import React,{useState} from "react";
import Autocomplete from 'react-google-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faCalendar,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
function TransferSearch(){
    const [pickUp, setPickUp] = useState([]);
    const [dropOf, setDropOf] = useState([]);
    const [transferDate, setTransferDate] = useState('');
    const [tripType, setTripType] = useState('');
    const [Passenger, setPassenger] = useState('');
    const handleTransferDateChange = date => {
        setTransferDate(date)
      };

      const handleSelectTripType=event=>{
        setTripType(event.target.value);
      };

      const handlePassenger=(event)=>{
        setPassenger( event.target.value);
        // setPassenger(prevdata=>({
        //     ...prevdata,
        //     passenger: event.target.value,
        // }))
      };

    return (
        <>
        <div className='tab-content'>
                <div className='tab-pane fade show active'>
                    <div className='row check-availabilty'>
                        <div className='row'>
                        <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black mb-2'
                           >
                            PICK-UP LOCATION
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faGlobe} />{' '}
                             </span>
                             <Autocomplete
                               className='form-control search-form-control text-start '
                               placeholder='Enter Location'
                               apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                               onPlaceSelected={place => {
                                setPickUp(place);
                                 
                               }}
                               options={{
                                 types: ['(regions)'],
                                 componentRestrictions: null
                               }}
                             />
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black mb-2'
                           >
                             DROP-OFF LOCATION
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faGlobe} />{' '}
                             </span>
                             <Autocomplete
                               className='form-control search-form-control text-start '
                               placeholder='Enter Location'
                               apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                               onPlaceSelected={place => {
                                setDropOf(place);
                               }}
                               options={{
                                 types: ['(regions)'],
                                 componentRestrictions: null
                               }}
                             />
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black mb-2'
                           >
                            PICK-UP DATE
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faCalendar} />{' '}
                             </span>
                             <DatePicker
                                selected={transferDate}
                                onChange={handleTransferDateChange}
                                placeholderText='Select Date'
                                dateFormat='dd/MM/yyyy' // Customize date format as needed
                                className='form-control text-start ps-5'
                            />
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black mb-2'
                           >
                            TRIP TYPE
                           </label>
                           <div className='field-icon-wrap'>
                             <select

                                value={tripType}
                                onChange={handleSelectTripType}
                                className='form-select'
                                aria-label='Default select example'
                            >
                                <option value='One-Way'>One-Way</option>
                                <option value='Return'>Return</option>
                                <option value='All_Round'>All_Round</option>

                            </select>
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black mb-2'
                           >
                           PASSENGER
                           </label>
                           <div className='field-icon-wrap'>
                            <input type="number" onChange={handlePassenger} value={Passenger} placeholder="Passenger" className="form-control"/>
                           </div>
                         </div>
                         <div className='col-md-6 col-lg-3 text-center align-self-end' style={{opacity:'0.7'}}>
                            <button  className='btn btn-primary btn-block select-styling search-btn1 rounded-1'>
                              <FontAwesomeIcon icon={faSearch} />  Search Here
                            </button>
                         </div>
                        </div>
                    </div>
                </div> 
        </div>   
        </>
    );
}

export default TransferSearch;