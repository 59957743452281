import React, {  createContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClockFour,
  faInfoCircle,
  faPlane
} from '@fortawesome/free-solid-svg-icons'
import {
  FlightSearchToken,
  Hotelapitoken,
  ApiEndPoint,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import img4 from '../Data/airline.png'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import '../Data/airline.css'
import Loading from '../Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  OneWayFlightCheckout,
  FlightCurrencyRates,
  fetchHotels,
  fetchHotelsSearh
} from '../../Redux/Actions/actions'
import { AirLineNames } from '../Data/AirLineNames'
import { airportcode } from '../Data/AirportCodes'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
function FlightCard ({ filterData }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var FlightresposeData = [];
  const [isLoading, setIsLoading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [baseCName, setBaseCName] = useState('GBP');
  const [showPrice, setShowPrice] = useState(true);
  const [showFilterData, setShowFilterData] = useState([]);
  const [displayedFlights, setDisplayedFlights] = useState(10);
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  var Airlinenamesdata = AirLineNames
  const SearchFlights = useSelector(state => state.hotels.OneWayFlight.PricedItineraries);
  if (SearchFlights.length !== 0) {

  var flightCurrency =SearchFlights[0].AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode}
  var FlightMarkup = JSON.parse(localStorage.getItem('FlightMarkup'));
  const IndexPageSearchData = useSelector( state => state.hotels.OneWayFlightSearchData);
  const apiend = ApiEndPoint();

  const handleScroll = () => {
    const windowHeight = window.innerHeight
    const scrollHeight = document.documentElement.scrollHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollHeight - (windowHeight + scrollTop) < 700) {
      setDisplayedFlights(displayedFlights + 10)
    }
  };

  useEffect(() => {
    const objectKeys = Object.keys(filterData);
    const objectLength = objectKeys.length
    if (filterData !== '' && objectLength > 1) {
      if (filterData.type === 'Stop') {
        if (filterData && filterData.hasOwnProperty('All')) {
          setShowFilterData(SearchFlights)
          return
        }
        const filteredFlights = SearchFlights.filter(flight => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return flight.OriginDestinationOptions[0].FlightSegments.some(
            flight1 => {
              var type = ''
              if (flight1.StopQuantity === 0) {
                type = 'Direct'
              } else if (flight1.StopQuantity !== 0) {
                type = 'OneStop'
              }
              for (const stoptype in filterData) {
                if (type === filterData[stoptype]) {
                  return true // Found a match, include the hotel
                }
              }
              return false // No match found for any meal key
            }
          )
        })
        setShowFilterData(filteredFlights)
      } else if (filterData.type === 'Airline') {
        const filteredFlights = SearchFlights.filter(flight => {
          const validatingAirlineCode = flight.ValidatingAirlineCode
          for (const stoptype in filterData) {
            if (validatingAirlineCode === filterData[stoptype]) {
              return true // Include the flight if it matches any of the specified airlines
            }
          }
          return false // Exclude the flight if it doesn't match any of the specified airlines
        })
        setShowFilterData(filteredFlights)
      } else if (filterData.type === 'Class') {
        const filteredFlights = SearchFlights.filter(flight => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return flight.OriginDestinationOptions[0].FlightSegments.some(
            flight1 => {
              for (const stoptype in filterData) {
                if (flight1.CabinClassType === filterData[stoptype]) {
                  return true // Found a match, include the hotel
                }
              }
              return false // No match found for any meal key
            }
          )
        })
        setShowFilterData(filteredFlights)
      }else if (filterData.type === 'Journey') {
        const filteredFlights = SearchFlights.filter(flight => {
          // Calculate the total duration of all flight segments for each flight
          const totalDuration = flight.OriginDestinationOptions[0].FlightSegments.reduce((sum, flightSegment) => {
            return sum + flightSegment.JourneyDuration;
          }, 0);
        
          // Convert the total duration to hours
          const totalHours = totalDuration / 60;
        
          // Check if the total duration is less than the specified time
          return totalHours < Number(filterData.time);
        });
        setShowFilterData(filteredFlights)
      }else if (filterData.type === 'DepartureTime') {
        const filteredFlights = SearchFlights.filter((flight) => {
          // Get the departure time of the first flight segment
          const firstSegmentDepartureTime = flight.OriginDestinationOptions[0]?.FlightSegments[0]?.DepartureDateTime;
         
          // Check if there is a valid departure time
          if (firstSegmentDepartureTime) {
            // Parse the departure time to compare with the selected range
            const departureTime = new Date(firstSegmentDepartureTime);
            const departureHours = departureTime.getHours();
            const departureMinutes = departureTime.getMinutes();
            // const departureHours = departureTime.getHours() * 60 + departureTime.getMinutes();
            const totalMinutes = departureHours * 60 + departureMinutes;
            const filterStartTime = timeToMinutes(filterData.time[0]);
            const filterEndTime = timeToMinutes(filterData.time[1]);
            // Check if the departure time is within the selected range
            return totalMinutes >= filterStartTime && totalMinutes <= filterEndTime;
          }
      
          // If no valid departure time, exclude the flight from the filtered results
          return false;
        });
        setShowFilterData(filteredFlights)
      }
    } else if (filterData === '1' || filterData === '3') {
      var Indirectflight = SearchFlights.filter(flight => {
        // Check if the first element of FlightSegments array has an index greater than one
        return flight.OriginDestinationOptions[0].FlightSegments.length > 1;
      });
      // Sort flights in ascending order based on totalamount
      var sotFlight = Indirectflight.sort(
        (a, b) =>
          Number(a.AirItineraryPricingInfo.PTC_FareBreakdowns[0]?.PassengerFare.TotalFare.Amount) -
          Number(b.AirItineraryPricingInfo.PTC_FareBreakdowns[0]?.PassengerFare.TotalFare.Amount)
      )
      setShowFilterData(sotFlight)
    } else if (filterData === '2' ) {
      const durations = [];
      SearchFlights.forEach((item) => {
        var sum = 0;
        item.OriginDestinationOptions[0].FlightSegments.forEach((item2) => {
          sum = sum + item2.JourneyDuration;
        });
        durations.push(sum);
      });
      
      // Now, sort the SearchFlights array based on the durations
      const sortedFlights = SearchFlights.slice(); // Create a copy of the array to avoid mutating the original array
      
      sortedFlights.sort((a, b) => {
        const durationA = durations[SearchFlights.indexOf(a)];
        const durationB = durations[SearchFlights.indexOf(b)];
      
        return durationA - durationB;
      });
      setShowFilterData(sortedFlights)
    }else if (filterData === '5') {
      bookHotel();
    }else if (filterData === '4') {
      bookHotel();
    } else {
      setShowFilterData(SearchFlights)
    }
  }, [filterData])

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    AllCurrency(flightCurrency)
  }, [baseCName])

  const BookFlight = async FareSource => {
    var token = FlightSearchToken()

    setIsLoading(true)
    const retrievedNumber = localStorage.getItem('15digitnumber')
    var data = {
      token_authorization: token,
      ConversationId: retrievedNumber,
      FareSourceCode: FareSource
    }
    try {
      const response = await Axios.post(
        apiend + '/api/flight_revalidation',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
          }
        }
      )
      setIsLoading(false)
      if (response.data.Success === false) {
        toast.info(response.data.Data.Errors[0].Message, {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (response.data.Success === true) {
        sessionStorage.setItem(
          'FlightCheckOut',
          JSON.stringify(response.data.Data)
        )
        dispatch(OneWayFlightCheckout(response.data.Data))
        ToggleModal();
      }
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price))
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price))
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1))
    }
    return baseprice
  };

  const AllCurrency = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        setBaseCurrency(response.data.conversion_rates)
        dispatch(FlightCurrencyRates(response.data.conversion_rates))
      })
      .catch(error => {
        // Handle errors here
        setShowPrice(false)
        console.error(error)
      })
  }

  const ToggleModal = () => {
    setOpenModal(!openModal)
  }
  const ProceedCheckout = () => {
    localStorage.removeItem('FlightCheckOut')
    navigate('/Flight_checkout')
  }

  const bookHotel = async () => {
    setIsLoading(true)

    const departureDateObj = new Date(IndexPageSearchData.DepartureDate)
    const checkOutDateObj = new Date(departureDateObj)
    checkOutDateObj.setDate(departureDateObj.getDate() + 4)
    const checkOutDate = checkOutDateObj.toISOString().split('T')[0]
    const filteredOptions = airportcode.items.filter(option =>
      option.airportCode
        .toLowerCase()
        .includes(IndexPageSearchData.ArrivalCode.toLowerCase())
    )
    var name =
      filteredOptions[0].cityName + ' ' + filteredOptions[0].countryName
    const apiKey = 'AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
    const city = name
    var latitude
    var longitude
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${city}&key=${apiKey}`
      )
      const data = await response.json()
      if (data.status === 'OK') {
        const result = data.results[0]
        latitude = result.geometry.location.lat
        longitude = result.geometry.location.lng
      } else {
        setIsLoading(false)
        console.log('Geocoding request failed with status: ', data.status)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
    var token = Hotelapitoken()
    const bookingData = {
      token: token,
      Adults: [IndexPageSearchData.adult],
      adult: IndexPageSearchData.adult,
      check_in: IndexPageSearchData.DepartureDate,
      check_out: checkOutDate,
      child: IndexPageSearchData.child,
      child_ages1: [],
      child_ages2: [],
      children: [0],
      cityd: filteredOptions[0].cityName,
      country: filteredOptions[0].countryName,
      country_code: filteredOptions[0].countryCode,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: filteredOptions[0].cityName,
      lat: latitude,
      long: longitude,
      pin: filteredOptions[0].countryCode,
      room: 1,
      rooms_counter: [1],
      slc_nationality: 'PK'
    }
    try {
      dispatch(fetchHotelsSearh(bookingData))
      const response = await Axios.post(
        apiend + '/api/search/hotels/new',
        bookingData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      //  console.log(response);
      dispatch(fetchHotels(response.data))
      if(filterData==='5' || filterData==='4'){
        openInNewTab('/hotels')
      }else{
        navigate('/hotels');
      }
      setIsLoading(false)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank');
    
  };

  const CalculateFLightMarkup = price => {
    var admin = 0
    var client = 0
    if (price !== 'NaN') {
      FlightMarkup.markups.forEach(markup => {
        if (markup.services_type === 'flight') {
          if (markup.added_markup === 'alhijaz') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = Number(markup.markup_value)
              const markupAmount = (Number(price) * markupValue) / 100
              client = markupAmount
            } else {
              client = Number(markup.markup_value)
            }
          } else if (markup.added_markup === 'synchtravel') {
            if (markup.markup_type === 'Percentage') {
              const markupValue = parseFloat(Number(markup.markup_value))
              const markupAmount = (Number(price) * markupValue) / 100
              admin = markupAmount
            } else {
              admin = Number(markup.markup_value)
            }
          }
        }
      })

      var total = Number(price) + admin + client
      return total.toFixed(2)
    }
  }
  return (
    <>
      {isLoading && <Loading />}
      <ToastContainer />
      {/***********  Model Windows ****************/}
      <Modal isOpen={openModal} toggle={ToggleModal}>
        <ModalHeader toggle={ToggleModal}>Book Another Service</ModalHeader>
        <ModalBody>
          <div className='text-center'>
            <h6>Do You Want To Another Service</h6>
            <button onClick={bookHotel} className='btn btn-success m-2'>
              Book Hotel
            </button>
            <h6>No</h6>
            <button onClick={ProceedCheckout} className='btn btn-danger m-2'>
              Proceed Checkout
            </button>
          </div>
        </ModalBody>

      </Modal>
      {Object.keys(filterData).length === 0 ? (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              {SearchFlights.slice(0, displayedFlights).map((item, index) => (
                <div key={index} className='col-md-12 mb-4'>
                  <div className='row parent_row'>
                    {/* <div style={{ background: 'aliceblue' }}>
                      <h5 className='card-title m-2'>
                        <a className='p-card-title'>
                          {Airlinenamesdata[item.ValidatingAirlineCode]
                            ? `${
                                Airlinenamesdata[item.ValidatingAirlineCode]
                                  .AirLineName
                              } `
                            : `Unknown Airline`}
                        </a>
                      </h5>
                    </div> */}
                    <div className='col-md-2 item-from'>
                      <div className=''>
                        <div
                          className={`image-cover_airline airline-sprite bg-${item.ValidatingAirlineCode}`}
                        ></div>
                      </div>
                    </div>
                    <div className='col-md-7 py-2' style={{ marginTop: 'auto', marginBottom: 'auto' }} >
                      <h5 className='card-title mt-2 mx-3'>
                        <a className='p-card-title'>
                          {Airlinenamesdata[item.ValidatingAirlineCode]
                            ? `${
                                Airlinenamesdata[item.ValidatingAirlineCode]
                                  .AirLineName
                              } `
                            : `Unknown Airline`}
                        </a>
                      </h5>
                      {item.OriginDestinationOptions.map((item3, upindex) => (
                        <div key={upindex}>
                          {item3.FlightSegments.map((flight, index) => (
                            <div
                              key={index}
                              class='fl-detail-left ms-0'
                              style={{ padding: '0' }}
                            >
                              <div class='fl-detail-left-container'>
                                <div class='fl-flight-schedual'>
                                  <div
                                    class='fl-flight-route'
                                    style={{ paddingBottom: '0' }}
                                  >
                                    <div class='fl-route-detail'>
                                      <h4 class='left'>
                                        {moment(
                                          flight.DepartureDateTime
                                        ).format('HH:mm')}
                                        <span>
                                          {' '}
                                          ({flight.DepartureAirportLocationCode}
                                          )
                                        </span>
                                      </h4>
                                      <h4 class='center fl-width text-center'>
                                        <FontAwesomeIcon
                                          color='gray'
                                          icon={faClockFour}
                                        />{' '}
                                        {/* {moment(flight.ArrivalDateTime).diff(moment(flight.DepartureDateTime), 'hours')}h{' '}
                            {moment(flight.ArrivalDateTime).diff(moment(flight.DepartureDateTime), 'minutes') % 60}m */}
                                        {Math.floor(
                                          flight.JourneyDuration / 60
                                        )}
                                        h {flight.JourneyDuration % 60}m
                                      </h4>
                                      <h4 class='right fl-width text-end'>
                                        {moment(flight.ArrivalDateTime).format(
                                          'HH:mm'
                                        )}
                                        <span>
                                          {' '}
                                          ({flight.ArrivalAirportLocationCode})
                                        </span>
                                      </h4>
                                    </div>
                                    <div class='fl-route-direction'>
                                      <div class='fl-route-bar'></div>
                                      <div class={`fl-icon ${upindex===1 ? 'filght-fa-rotate': ''}`}>
                                        <FontAwesomeIcon  icon={faPlane} />
                                      </div>
                                    </div>
                                    <div class='fl-route-detail'>
                                      <p
                                        class='left'
                                        style={{ fontSize: '.9em' }}
                                      >
                                        {' '}
                                        <br />{' '}
                                        {moment(
                                          flight.DepartureDateTime
                                        ).format('ll')}
                                      </p>
                                      <p
                                        class='center text-center'
                                        style={{ fontSize: '.9em' }}
                                      >
                                        {/* Direct */}
                                      </p>
                                      <p
                                        class='right text-end'
                                        style={{ fontSize: '.9em' }}
                                      >
                                        <br />{' '}
                                        {moment(flight.ArrivalDateTime).format(
                                          'll'
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className='col-md-3 text-center py-5' style={{borderLeft:'1px solid #dedede'}}>
                      <h6 className='text-center p-view-detail'>
                        {/* <NavLink className='nav-link' to='/hotel_detail'>
                        Map View
                      </NavLink> */}
                      </h6>
                      <div className='price text-center mt-2'>
                        {showPrice ? (
                          <h6>
                            {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map(
                              (item1, index) => (
                                <div key={index}>
                                  {item1.PassengerTypeQuantity.Code ===
                                    'ADT' && (
                                    <super>
                                      {CurrencyRates === undefined
                                        ? baseCName
                                        : CurrencyRates.selectedcurrency}{' '}
                                      {CalculateFLightMarkup(
                                        renderPrice(
                                          Number(
                                            item1.PassengerFare.TotalFare.Amount
                                          ) *
                                            item1.PassengerTypeQuantity
                                              .Quantity
                                        )
                                      )}
                                    </super>
                                  )}
                                </div>
                              )
                            )}
                            {/* <sub>PP</sub> */}
                          </h6>
                        ) : (
                          <h6>
                            {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map(
                              (item1, index) => (
                                <div key={index}>
                                  {item1.PassengerTypeQuantity.Code ===
                                    'ADT' && (
                                    <super>
                                      {
                                        item1.PassengerFare.TotalFare
                                          .CurrencyCode
                                      }{' '}
                                      {CalculateFLightMarkup(
                                        Number(
                                          item1.PassengerFare.TotalFare.Amount
                                        ) * item1.PassengerTypeQuantity.Quantity
                                      )}
                                    </super>
                                  )}
                                </div>
                              )
                            )}
                            {/* <super>{item.AirItineraryPricingInfo.PTC_FareBreakdowns.ItinTotalFare.TotalFare.CurrencyCode} {CalculateFLightMarkup(item.AirItineraryPricingInfo.PTC_FareBreakdowns.TotalFare.Amount)}</super> */}
                            {/* <sub>PP</sub> */}
                          </h6>
                        )}
                        {/* {index==0 && (item.AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode)} */}
                      </div>
                      <div className='mt-2' style={{ fontSize: '11px' }}>
                        <FontAwesomeIcon
                          color='#bd1c1cc9'
                          icon={faInfoCircle}
                        />{' '}
                        Exclude Fare
                      </div>
                      <button
                        onClick={() =>
                          BookFlight(
                            item.AirItineraryPricingInfo.FareSourceCode
                          )
                        }
                        className='btn btn-primary select-styling search-btn1 mb-2 mt-2 form-control rounded-1'
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
              {showFilterData.slice(0, displayedFlights).map((item, index) => (
                <div key={index} className='col-md-12 mb-4'>
                  <div className='row parent_row'>
                    {/* <div style={{ background: 'aliceblue' }}>
                      <h5 className='card-title m-2'>
                        <a className='p-card-title'>
                          {Airlinenamesdata[item.ValidatingAirlineCode]
                            ? `${
                                Airlinenamesdata[item.ValidatingAirlineCode]
                                  .AirLineName
                              } `
                            : `Unknown Airline`}
                        </a>
                      </h5>
                    </div> */}
                    <div className='col-md-2 item-from'>
                      <div className=''>
                        <div
                          style={{ backgroundImage: `url(${img4})` }}
                          className={`image-cover_airline bg-${item.ValidatingAirlineCode}`}
                        ></div>
                      </div>
                    </div>
                    <div className='col-md-7 pb-2' style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                       <h5 className='card-title mx-3 mt-3'>
                        <a className='p-card-title'>
                          {/* {Airlinenamesdata[item.ValidatingAirlineCode].AirLineName} */}
                          {Airlinenamesdata[item.ValidatingAirlineCode]
                            ? `${
                                Airlinenamesdata[item.ValidatingAirlineCode]
                                  .AirLineName
                              } `
                            : `Unknown Airline`}
                        </a>
                      </h5>
                      {item.OriginDestinationOptions.map((item3, index) => (
                        <div key={index}>
                          {item3.FlightSegments.map((flight, index) => (
                            <div
                              key={index}
                              class='fl-detail-left ms-0'
                              style={{ padding: '0' }}
                            >
                              <div class='fl-detail-left-container'>
                                <div class='fl-flight-schedual'>
                                  <div
                                    class='fl-flight-route'
                                    style={{ paddingBottom: '0' }}
                                  >
                                    <div class='fl-route-detail'>
                                      <h4 class='left'>
                                        {moment(
                                          flight.DepartureDateTime
                                        ).format('HH:mm')}
                                        <span>
                                          {' '}
                                          ({flight.DepartureAirportLocationCode}
                                          )
                                        </span>
                                      </h4>
                                      <h4 class='center fl-width text-center'>
                                        <FontAwesomeIcon
                                          color='gray'
                                          icon={faClockFour}
                                        />{' '}
                                        {Math.floor(
                                          flight.JourneyDuration / 60
                                        )}
                                        h {flight.JourneyDuration % 60}m
                                      </h4>
                                      <h4 class='right fl-width text-end'>
                                        {moment(flight.ArrivalDateTime).format(
                                         'HH:mm'
                                        )}
                                        <span>
                                          {' '}
                                          ({flight.ArrivalAirportLocationCode})
                                        </span>
                                      </h4>
                                    </div>
                                    <div class='fl-route-direction'>
                                      <div class='fl-route-bar'></div>
                                      <div class='fl-icon'>
                                        <FontAwesomeIcon icon={faPlane} />
                                      </div>
                                    </div>
                                    <div class='fl-route-detail'>
                                      <p
                                        class='left'
                                        style={{ fontSize: '.9em' }}
                                      >
                                        {' '}
                                        <br />{' '}
                                        {moment(
                                          flight.DepartureDateTime
                                        ).format('ll')}
                                      </p>
                                      <p
                                        class='center text-center'
                                        style={{ fontSize: '.9em' }}
                                      ></p>
                                      <p
                                        class='right text-end'
                                        style={{ fontSize: '.9em' }}
                                      >
                                        <br />{' '}
                                        {moment(flight.ArrivalDateTime).format(
                                          'll'
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className='col-md-3 text-center py-5' style={{borderLeft:'1px solid #dedede'}}>
                      <div className='price text-center p-card-price mt-2'>
                        {showPrice ? (
                          <h6>
                            {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map(
                              (item1, index) => (
                                <div key={index}>
                                  {item1.PassengerTypeQuantity.Code ===
                                    'ADT' && (
                                    <super>
                                      {CurrencyRates === undefined
                                        ? baseCName
                                        : CurrencyRates.selectedcurrency}{' '}
                                      {CalculateFLightMarkup(
                                        renderPrice(
                                          Number(
                                            item1.PassengerFare.TotalFare.Amount
                                          ) *
                                            item1.PassengerTypeQuantity
                                              .Quantity,
                                          item1.PassengerFare.TotalFare
                                            .CurrencyCode
                                        )
                                      )}
                                    </super>
                                  )}
                                </div>
                              )
                            )}{' '}
                            {/* <sub>PP</sub> */}
                          </h6>
                        ) : (
                          <h6>
                            {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map(
                              (item1, index) => (
                                <div key={index}>
                                  {item1.PassengerTypeQuantity.Code ===
                                    'ADT' && (
                                    <super>
                                      {
                                        item1.PassengerFare.TotalFare
                                          .CurrencyCode
                                      }{' '}
                                      {CalculateFLightMarkup(
                                        Number(
                                          item1.PassengerFare.TotalFare.Amount
                                        ) * item1.PassengerTypeQuantity.Quantity
                                      )}
                                    </super>
                                  )}
                                </div>
                              )
                            )}{' '}
                            {/* <sub>PP</sub> */}
                          </h6>
                        )}
                        {/* {index==0 && (item.AirItineraryPricingInfo.ItinTotalFare.TotalFare.CurrencyCode)} */}
                      </div>
                      <div className='mt-2' style={{ fontSize: '11px' }}>
                        <FontAwesomeIcon
                          color='#bd1c1cc9'
                          icon={faInfoCircle}
                        />{' '}
                        Exclude Fare
                      </div>
                      <button
                        onClick={() =>
                          BookFlight(
                            item.AirItineraryPricingInfo.FareSourceCode
                          )
                        }
                        className='btn btn-primary select-styling search-btn1 mb-2 mt-2 form-control rounded-1'
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FlightCard
