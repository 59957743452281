import React, { useState, useEffect } from 'react'
import FlightDetail from './FlightCard'
import Faqs from '../../Components/FAQs/FAQs'
import Layout from '../../Components/Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPhone,
  faMapMarker,
  faPersonCirclePlus,
  faPersonCircleMinus,
  faCalendar,
  faMoon,
  faClipboardCheck,
  faBus,
  faBuildingCircleCheck,
  faPlaneDeparture,
  faPlaneArrival,
  faBed,
  faClock,
  faDollar,
  faHeadphones,
  faStar,
  faLock,
  faMailBulk,
  faEnvelope,
  faCalendarCheck,
  faCalendarMinus,
  faBuildingColumns,
  faWheelchair,
  faHeart,
  faCat,
  faVolumeHigh,
  faUserTie,
  faCalendarDays,
  faLocationDot,
  faCalendarDay
} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import kaba from '../../Images/Packages/kabapic.jpg';
import busimage from '../../Images/Logo/bus.jpg' 
import { CustomerDomainName } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import Images from '../../Components/Images/images'

import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
function ViewDeail () {
  var url = CustomerDomainName();
  const tourDetail = useSelector(state => state.hotels.viewtourdetail);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const tourlocation = JSON.parse(tourDetail.tours.tour_location);
  const transportationdetail = JSON.parse(
    tourDetail.tours.transportation_details
  )
  const Itinerarydetail = JSON.parse(tourDetail.tours.Itinerary_details)
  var Itinerarydetailmore=[]
  if(tourDetail.tours.tour_itinerary_details_1 !== null){
  Itinerarydetailmore = JSON.parse(
    tourDetail.tours.tour_itinerary_details_1
  )
}
  const accomodationdetail = JSON.parse(tourDetail.tours.accomodation_details)
  const accomodationdetailmore = JSON.parse(
    tourDetail.tours.accomodation_details_more
  )
  const transportationdetailmore = tourDetail.tours.transportation_details_more
  ? JSON.parse(tourDetail.tours.transportation_details_more)
  : [];
  const images = JSON.parse(tourDetail.tours.gallery_images)
  const [opencartmodel, setOpencartmodel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [minValue, setMinValue] = useState({price:0,currency:''})
  const [modalAdultRoom, setModalAdultRoom] = useState({double:'',triple:'',quad:''});
  const [modalAdultAdult, setModalAdultAdult] = useState({double:'',triple:'',quad:''});
  const [modalAdultTotal, setModalAdultTotal] = useState({double:0,triple:0,quad:0});

  const [activeButton, setActiveButton] = useState(null)

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  useEffect(() => {
    calcalutemin();
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  const toggleModal2=()=>{
    setCancelModal(!cancelModal)
  }
const calcalutemin=()=>{
  var min_value = Math.min(tourDetail.tours.double_grand_total_amount, tourDetail.tours.triple_grand_total_amount, tourDetail.tours.quad_grand_total_amount);
  if (minValue.price !== min_value) {
   setMinValue({price:min_value,currency:tourDetail.tours.currency_symbol});
 }

};
  return (
    <>
      <Layout>
        <div className='contact-img'>
          <div className='text-overimg'>        
            <img src={kaba} />
          </div>
        </div>
        <div className='mt-3'>
          <Images data={images} setting={1} style={{height:'50%'}} />
        </div>
        <nav className='navbar view-detail-navbar'>
          <div className='container'>
            <ul>
              <li>
                <a
                  href='#section-1'
                  onClick={() => handleButtonClick('section-1')}
                  className={activeButton === 'section-1' ? 'current' : ''}
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                  href='#section-2'
                  onClick={() => handleButtonClick('section-2')}
                  className={activeButton === 'section-2' ? 'current' : ''}
                >
                  Itinerary Schedule
                </a>
              </li>
              <li>
                <a
                  href='#section-3'
                  onClick={() => handleButtonClick('section-3')}
                  className={activeButton === 'section-3' ? 'current' : ''}
                >
                  Flights
                </a>
              </li>
              <li>
                <a
                  href='#section-4'
                  onClick={() => handleButtonClick('section-4')}
                  className={activeButton === 'section-4' ? 'current' : ''}
                >
                  Accomodation
                </a>
              </li>
              <li>
                <a
                  href='#section-5'
                  onClick={() => handleButtonClick('section-5')}
                  className={activeButton === 'section-5' ? 'current' : ''}
                >
                  Transportion
                </a>
              </li>
              <li>
                <a
                  href='#section-6'
                  onClick={() => handleButtonClick('section-6')}
                  className={activeButton === 'section-6' ? 'current' : ''}
                >
                  Visa
                </a>
              </li>
              <li>
                <a
                  href='#section-7'
                  onClick={() => handleButtonClick('section-7')}
                  className={activeButton === 'section-7' ? 'current' : ''}
                >
                  FAQ
                </a>
              </li>
            </ul>
            <div>

              <h6 className='price-start'>Price Start From : {minValue.currency} {minValue.price}</h6>
            </div>

          </div>
        </nav>
        <div className='container'>
          <div className='row'>
            <div class='col-sm-12 col-md-8'>
              <div className='row mt-5 pb-3 mb-3' style={{color:'GrayText', borderBottom:'2px solid black', textAlign:'center'}}>
                <div className='col-md-5'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <i class="fa-solid fa-building-columns" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faBuildingColumns} /></i>
                      <p>Museum</p>
                    </div>
                    <div className='col-md-4'>
                      <i class="fa-solid fa-building-columns" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faClock} /></i>
                      <p>3 Hours</p>
                    </div>
                    <div className='col-md-4'>
                      <i class="fa-solid fa-wheelchair" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faWheelchair} /></i>
                      <p>Accessibility</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <i class="fa-solid fa-heart" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faHeart} /></i>
                      <p>144 Likes</p>
                    </div>
                    <div className='col-md-3'>
                      <i class="fa-solid fa-cat" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faCat} /></i>
                      <p>Pet Allowed</p>
                    </div>
                    <div className='col-md-3'>
                      <i class="fa-solid fa-volume-high" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faVolumeHigh} /></i>
                      <p>Audio guide</p>
                    </div>
                    <div className='col-md-3'>
                      <i class="fa-solid fa-user-tie" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faUserTie} /></i>
                      <p>Tour guide</p>
                    </div>
                  </div>
                </div>
              </div>
              <section id='section-1' className='pt-2'>
                <div class='row'>
                 <div className='col-md-3'><h3>Description</h3></div>
                 <div className='col-md-9'>
                    <div class='col-md-12'>
                      <div class='product-title'>
                        <h3 className='view-detail-title'>{tourDetail.tours.title}</h3>
                      </div>
                      <div class='product-address'>
                        <span>
                          {' '}
                          <i class='fa-solid tc fa-location-dot'>
                            <FontAwesomeIcon icon={faMapMarker} />
                          </i>
                          &nbsp;
                          {tourlocation.map((location, index) => (
                            <span key={index}>
                              {location}
                              {index !== tourlocation.length - 1 ? ' ' : ''}
                            </span>
                          ))}
                          &nbsp;
                          <i class='fa-solid tc fa-phone'>
                            <FontAwesomeIcon icon={faPhone} />
                          </i>
                          &nbsp; 0121 777 2522
                        </span>
                      </div>
                      <p class='mt-2 mb-2'>{tourDetail.tours.content}</p>
                    </div>
                    <div className='row mt-3'>
                      <div class='col-md-6'>
                        <h6>
                          {' '}
                          <i class='fa-solid fa-person-circle-plus'>
                            {' '}
                            <FontAwesomeIcon icon={faPersonCirclePlus} />
                          </i>{' '}
                          Whats Included?
                        </h6>
                        <p></p>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tourDetail.tours.whats_included
                            }}
                          ></span>
                          <br />
                        </p>
                        <p></p>
                      </div>
                      <div class='col-md-6'>
                        <h6>
                          <i class='fa-solid fa-person-circle-minus'>
                            <FontAwesomeIcon icon={faPersonCircleMinus} />
                          </i>{' '}
                          Whats Excluded?
                        </h6>
                        <p></p>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tourDetail.tours.whats_excluded
                            }}
                          ></span>
                          <br />
                        </p>
                        <p></p>
                      </div>
                    </div>
                 </div>
                </div>
              </section>
              <section id='section-2' className='row mt-3'>
                <div className='col-md-3'>
                   <h3 className=''>
                   {/* Itinerary  */}
                   Schedule</h3>
                </div>
                <div className='col-md-9'>
                    <button
                      className={`accordion  ${activeIndex === 0 ? 'active' : ''}`}
                      onClick={() => show(0)}
                    >
                      {Itinerarydetail[0].Itinerary_title}:{' '}
                      {Itinerarydetail[0].Itinerary_content}
                    </button>
                    <div className='panel'>
                      <p>{Itinerarydetail[0].Itinerary_city}</p>
                    </div>
                    {Itinerarydetailmore.map((item, index) => (
                      <div key={index}>
                        <button
                          className={`accordion  ${
                            activeIndex === index + 1 ? 'active' : ''
                          }`}
                          onClick={() => show(index + 1)}
                        >
                          {item.more_Itinerary_title} : {item.more_Itinerary_city}
                        </button>
                        <div class='panel'>
                          <p>{item.more_Itinerary_content}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
              <section id='section-3' className='row mt-5'>
                 <div className='col-md-3'>
                    <h4 className=''>Flight Details</h4>
                 </div>
                 <div className='col-md-9'>
                     <FlightDetail />
                 </div>
              </section>
              <section id='section-4' className='hotel-detail-padding'>
                {accomodationdetail.map((item, index) => {
                  const hotels = accomodationdetailmore.filter(
                    x => x.more_hotel_city.toUpperCase() === item.hotel_city_name.toUpperCase()
                  )

                  return (
                    <div key={index} class='hotel-details-top row mt-5'>
                      <h4 className='col-md-3'>
                        {item.hotel_city_name} Hotel Details
                      </h4>
                      <div className='col-md-9'>
                        <div class='row'>
                         
                          <div class='col-sm-12 col-md-6'>
                            <h5>{item.acc_hotel_name}</h5>
                            <ul>
                              <li className='mt-1'>
                                <i class='fa-solid fa-bed'>
                                  <FontAwesomeIcon icon={faBed} />
                                </i>{' '}
                                Room Type: {item.acc_type}
                                {hotels.map((a, index) => (
                                  <span key={index}>/{a.more_acc_type} </span>
                                ))}
                                <span></span>
                              </li>
                              <li className='mt-1'>
                                {' '}
                                <i class='fa-solid fa-bed'>
                                  <FontAwesomeIcon icon={faCalendarCheck} />
                                </i>{' '}
                                Check In : {moment(item.acc_check_in).format('DD-MM-YYYY')}
                              </li>
                              <li className='mt-1'>
                                {' '}
                                <i class='fa-solid fa-bed'>
                                  <FontAwesomeIcon icon={faCalendarMinus} />
                                </i>{' '}
                                Check Out : {moment(item.acc_check_out).format('DD-MM-YYYY')}
                              </li>
                              <li className='mt-1'>
                                {' '}
                                <i class='fa-solid fa-bed'>
                                  <FontAwesomeIcon icon={faMoon} />
                                </i>{' '}
                                No Of Nights : {item.acc_no_of_nightst}
                              </li>
                            </ul>
                          </div>
                          <div class='col-sm-12 col-md-6'>
                            <h5>Room Amenities </h5>
                            <p className='mb-2'>{item.hotel_whats_included}</p>
                              <img
                                src={
                                  url +
                                  '/public/uploads/package_imgs/' +
                                  item.accomodation_image[0]
                                }
                                className='h-50 w-100'
                                alt=''
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </section>
              <section id='section-5' className='row mt-5'>
                <div className='col-md-3'>
                  <h4>Transfer Details</h4>
                </div>
                <div className='col-md-9'>
                    <div className='row'>
                      <div className='col-sm-12 col-md-6 item-from'>
                        <div class=''>
                          <h6 className='tc mb-2'>Pick-up</h6>
                          <div className='d-flex'>
                            <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faCalendarDay} /></i>{' '}
                            <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                              {moment(
                                transportationdetail[0].transportation_pick_up_date
                              ).format('DD-MM-YYYY')}{' '}
                            </h6>
                          </div>
                          <div className='d-flex'>
                            <i className='tc' style={{fontSize:'14px'}}><FontAwesomeIcon icon={faLocationDot} /></i>{' '}
                            <h6  className='mx-2' style={{ fontSize: '1rem' }}>
                              {
                                transportationdetail[0]
                                  .transportation_pick_up_location
                              }
                            </h6>
                          </div>
                          <div className='d-flex'>
                            <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faClock} /></i>{' '}
                            <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                              {moment(
                                transportationdetail[0].transportation_pick_up_date
                              ).format('LT')}{' '}
                            
                            </h6>
                          </div>
                          <div class='d-flex tr-vehicle-name'>
                            <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faBus} /></i>{' '}{' '}
                            <h6 className='mx-2' style={{ fontSize: '.8rem' }}> Vehicle: </h6>{' '}
                            <h6 style={{ fontSize: '.8rem' }}> {transportationdetail[0].transportation_vehicle_type}</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 item-from'>
                        <div class=''>
                          <h6 className='tc mb-2'>Drop-off</h6>
                          <div className='d-flex'>
                            <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faCalendarDay} /></i>{' '}
                            <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                              {moment(
                                transportationdetail[0].transportation_drop_of_date
                              ).format('DD-MM-YYYY')}{' '}
                            </h6>
                          </div>
                          <div className='d-flex'>
                            <i className='tc' style={{fontSize:'14px'}}><FontAwesomeIcon icon={faLocationDot} /></i>{' '}
                            <h6 className='mx-2' style={{ fontSize: '1rem' }}>
                              {
                                transportationdetail[0]
                                  .transportation_drop_off_location
                              }
                            </h6>
                          </div>
                           <div className='d-flex'>
                              <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faClock} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                                {moment(
                                  transportationdetail[0].transportation_drop_of_date
                                ).format('LT')}{' '}
                              </h6>
                              <span>  {transportationdetail[0].transportation_total_Time} </span>
                           </div>
                        </div>
                      </div>
                    </div>
                    {transportationdetailmore.map((item, index) => (
                      <div
                        key={index}
                        className='row mt-4'
                        style={{ borderTop: '2px solid #8080804d' }}
                      >
                          {/* <div class='image-thumb'>
                            <img
                              src='https://demo2.synchronousdigital.com/public/admin_package/frontend/images/bus.jpg'
                              alt=''
                              height='100'
                            />
                          </div> */}
                        <div className='col-sm-12 col-md-6 item-from pt-2'>
                          <div class=''>
                            <h6 className='tc mb-2'> Pick-up</h6>
                            <div className='d-flex'>
                              <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faCalendarDay} /></i>{' '}
                              <h6 className='mx-2'  style={{ fontSize: '.8rem' }}>
                                {moment(item.more_transportation_pick_up_date).format(
                                  'DD-MM-YYYY'
                                )}{' '}
                              </h6>
                            </div>
                            <div className='d-flex'>
                              <i className='tc' style={{fontSize:'14px'}}><FontAwesomeIcon icon={faLocationDot} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '1rem' }}>
                                {item.more_transportation_pick_up_location}
                              </h6>
                            </div>
                            <div className='d-flex'>
                              <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faClock} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                                {moment(item.more_transportation_pick_up_date).format(
                                  'LT'
                                )}{' '}
                              </h6>
                            </div>
                            <div class='d-flex tr-vehicle-name'>
                              <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faBus} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '.8rem' }}> Vehicle: {' '} {transportationdetail[0].transportation_vehicle_type}</h6>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-6 item-from pt-2'>
                          <div class=''>
                            <p className='tc fw-bold mb-2'> Drop-off</p>
                            <div className='d-flex'>
                              <i className='tc' style={{fontSize:'12px'}}><FontAwesomeIcon icon={faCalendarDay} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                                {moment(item.more_transportation_drop_of_date).format(
                                  'DD-MM-YYYY'
                                )}{' '}
                              </h6>
                            </div>
                            <div className='d-flex'>
                                <i className='tc' style={{fontSize:'14px'}}><FontAwesomeIcon icon={faLocationDot} /></i>{' '}
                                <h6 className='mx-2' style={{ fontSize: '1rem' }}>
                                  {item.more_transportation_drop_off_location}
                                </h6>
                            </div>
                            <div className='d-flex'>
                              <i className='tc' style={{fontSize:'14px'}}><FontAwesomeIcon icon={faLocationDot} /></i>{' '}
                              <h6 className='mx-2' style={{ fontSize: '.8rem' }}>
                                {moment(item.more_transportation_drop_of_date).format(
                                  'LT'
                                )}{' '}
                              </h6>
                              <span>{item.more_transportation_total_Time}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
              <section id='section-6' className='row mt-5'>
                <div className='col-md-3'>
                  <h4>Visa Details</h4>
                </div>
                <div className='col-md-9'>
                  <div className='row'>
                    <div className='col-sm-6 col-8 col-md-4 text-center item-from'>
                      <div class='d-flex'>
                        <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                          Visa Type : {'  '}
                        </span>
                        <span className='mx-2' style={{ fontSize: '1rem', color: 'gray' }}>
                        {'  '} {tourDetail.tours.visa_type}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-12 col-8 col-md-5 text-justify item-from'>
                      <h6 style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                        Visa Requirements
                      </h6>
                      <h6 style={{ fontSize: '.8rem', color: 'gray' }}>
                        {/* {tourDetail.tours.visa_detials} */}
                      </h6>
                    </div>
                    <div className='col-sm-6 col-4 col-md-3 text-center'>
                      <div class='image-thumb'>
                        <img
                          src={
                            url +
                            '/public/uploads/package_imgs/' +
                            tourDetail.tours.visa_image
                          }
                          alt=''
                          height='100'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Faqs />
            </div>
            <div className='col-md-4 mt-4'>
              <div className='theiaStickySidebar'>
                <div className='px-0' style={{border:'1px solid #e1dbdb'}}>
                  <div style={{backgroundColor:'#565151', color:'white'}}>
                     <h3 className='py-2 text-center'>- Booking -</h3>
                  </div>
                  <div className='px-4 mt-4'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-6 mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}>
                             <i class='fa tc fa-calendar' aria-hidden='true'>
                              <FontAwesomeIcon icon={faCalendar} />
                            </i>{' '} Departure Date</h6>
                            <input type='text' className='mt-2 w-100' value={moment(tourDetail.tours.start_date).format(
                              'DD-MM-YYYY'
                            )} />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }} class='title fw-bold'> 
                            <i class='fa tc fa-calendar' aria-hidden='true'>
                              <FontAwesomeIcon icon={faCalendar} />
                            </i>{' '}
                            Return Date</h6>
                            <input type='text' value={moment(tourDetail.tours.end_date).format('DD-MM-YYYY')} className='mt-2 w-100' />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }} class='title fw-bold'> 
                            <i class='fa tc fa-calendar' aria-hidden='true'>
                              <FontAwesomeIcon icon={faMoon} />
                            </i>{' '}
                            Time length</h6>
                            <input type='text' value={tourDetail.tours.time_duration + '' + 'Nights'} className='mt-2 w-100' />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }} class='title fw-bold'> 
                            <i class='fa tc fa-calendar' aria-hidden='true'>
                              <FontAwesomeIcon icon={faBus} />
                            </i>{' '}
                            Transport</h6>
                            <input type='text' value={transportationdetail[0].transportation_vehicle_type} className='mt-2 w-100' />
                        </div>
                      </div>
                    </div>
                    <ul class='list-items list-items-2 py-2 mt-2'>
                      <div className='border-line'></div>
                      <li className='py-2'>
                        <span>Category :</span>
                        <span style={{float:'right'}}>
                          <i class='fa-solid tc fa-clipboard-check'>
                            <FontAwesomeIcon icon={faClipboardCheck} />
                          </i>{' '}
                        </span>
                      </li>
                      <div className='border-line'></div>
                      <li className='py-2'>
                        <span>Destinations:</span>
                        <span title='Makkah, Madina' style={{float:'right'}}>
                            <i class='fa-solid tc fa-building-circle-check'>
                              <FontAwesomeIcon icon={faBuildingCircleCheck} />
                              {' '}
                            </i>
                            {tourlocation.map((location, index) => (
                              <span key={index}>
                                {location},
                                {index !== tourlocation.length - 1 ? ' ' : ''}
                              </span>
                            ))}
                        </span>
                      </li>
                      <div className='border-line'></div>
                      <li className='py-2'>
                        <span>Double Price:</span>
                        <span style={{float:'right'}}>
                            {tourDetail.tours.currency_symbol}{' '}
                              {tourDetail.tours.double_grand_total_amount}
                        </span>
                      </li>
                      <div className='border-line'></div>
                      <li className='py-2'>
                        <span>Triple Price</span>
                        <span style={{float:'right'}}>
                            {tourDetail.tours.currency_symbol}{' '}
                            {tourDetail.tours.triple_grand_total_amount}
                        </span>
                      </li>
                      <div className='border-line'></div>
                      <li className='py-2'>
                         <span>Quad Price</span>
                        <span style={{float:'right'}}>
                             {tourDetail.tours.currency_symbol}{' '}
                             {tourDetail.tours.quad_grand_total_amount}
                        </span>
                      </li>
                      <div className='border-line'></div>
                    </ul>
                    <div class='row mt-2'>
                      <button onClick={toggleModal2} className='btn select-styling detail-view-btn search-btn1 rounded-1'> Cancellation Policy ?</button>
                    </div>
                    <div class='row my-3'>
                      <button className='btn detail-view-btn rounded-1' style={{border:'2px solid black'}}> Send Email Inquiry</button>
                    </div>
                  </div>
                </div>
                <div className='book-package-2'>
                  <h6>Why Book with us?</h6>
                <ul class='list-items book-package-3  list-items-2 mt-2 py-2'>
                <li className=' fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faDollar}/></span>No-hassle best price guarantee
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faHeadphones}/></span>Customer care available 24/7
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faStar}/></span>Picked Packages to your Need
                  </li>
                  <div className='border-line'></div>
                  <li className='mt-2 fs-6'>
                    <span className='book-package-side-box'><FontAwesomeIcon icon={faLock}/></span>Secure Payment & Privacy
                  </li>
                </ul>
                </div>
                <div className='book-package-4'>
                  <h3 >Got a Question?</h3>
                  <div>
                    <p  className='mt-3'>Do not hesitate to give us a call. Our expert team would be happy to help you.</p>
                    <h5  className='mt-3'><FontAwesomeIcon icon={faPhone}/> 0121 777 2522</h5>
                    <h5  className='mt-3'><FontAwesomeIcon icon={faEnvelope}/> info@alhijaztours.net</h5>
                  </div>
                </div>
                <div className='book-now text-center cart-btn'>
                  <NavLink to='/book_package'>
                    <button class='btn select-styling search-btn1 mt-2 detail-view-btn rounded-1 py-2'> Add to Cart </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={cancelModal} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}> Cancellation Policy</ModalHeader>
        <ModalBody>
          <div className='form-group'>
           <p>{tourDetail.tours.cancellation_policy}</p>
          </div>
        </ModalBody>
      </Modal>
      <div id="mybutton">
      <NavLink to='/book_package'>
        <button class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn rounded-1">Add to Cart</button>
      </NavLink>
      </div>
    </>
  )
}

export default ViewDeail
