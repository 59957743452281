import React, { useState, useEffect } from 'react'
import logo from '../../Images/Logo/logo.png'
import Axios from 'axios'
import en from '../../Images/british.png';
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import wp from '../../Images/wp.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faUser,
  faEnvelope,
  faF,
  faG,
  faW,
  faY,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faSearch,
  faHeart
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { set } from 'date-fns'
import Select from 'react-select';

function Navbar () {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
        
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.setItem('Catogories',JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories)
    } catch (error) {
      console.error('Error:', error)
    }
  };

  const FindPackageDetail = id => {
    toggleDropdown()
    navigation('/umrah_packages', { state: id })
  }

  return (
    <>
      <header>
        <div className='navbar-top'>
          <nav className="navbar-expand-lg navbar text-dark border-bottom py-0" style={{backgroundColor:'white', opacity:'0.9'}}>
            <div className="container">
              <a className="navbar-brand p-1 d-flex" href="..">
                <h6> <span className='text-success'> <FontAwesomeIcon icon={faPhone} /> </span>00212524438627</h6>
                <h6 className='mx-3'> <span className='text-success'> <FontAwesomeIcon icon={faPhone} /> </span>00212620081835</h6>
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar1" aria-controls="offcanvasNavbar1" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button> 
              <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar1" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                  {/* <a className="navbar-brand p-1" href="..">
                    <h4> <span className='text-success'> <FontAwesomeIcon icon={faPhone} /> </span>00212524438627</h4>
                    <h4 className='mx-2'> <span className='text-success'> <FontAwesomeIcon icon={faPhone} /> </span>00212524438627</h4>
                  </a> */}
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav navbar-link d-flex justify-content-center flex-grow-1 column-gap-2">
                    </ul>
                    <li className='nav-item nav-link scrollto m-2 d-inline'>
                      <NavLink className='nav-link mt-1' to='...' style={{fontFamily:'arial'}}>   
                      <span className='text-success'><FontAwesomeIcon icon={faUser} /></span>
                      {' '} Log in  </NavLink>
                    </li>
                    <li className='nav-item nav-link scrollto m-2 px-2 d-inline' style={{borderRight:'1px solid #dad7d7', borderLeft:'1px solid #dad7d7'}}>
                      <NavLink className='nav-link mt-1' to='...' style={{fontFamily:'arial'}}>   
                      <span className='text-success'><FontAwesomeIcon icon={faHeart} /></span>
                      {' '} Wishlist </NavLink>
                    </li>
                    <li className='nav-item nav-link scrollto'>
                      <div id="ctsettingswidget-3" class="contact-box small-box widget_ctsettingswidget mt-1">
                              <button
                              style={{color:'black'}}
                            onClick={toggleModal}
                            className='btn btn-primary currency-btn'
                          >
                            {selectedCurrency}
                          </button>
                        {/* <select class="form-control cl-switcher" name="currency" id="currency" style={{height:'27px', appearance:'auto', backgroundColor:'white'}}>
                          <option data-url="/?selected_currency=mad">MAD</option>
                          <option selected="" data-url="/?selected_currency=pkr">PKR</option>
                          <option data-url="/?selected_currency=eur">EUR</option>
                          <option data-url="/?selected_currency=gbp">GBP</option>
                          <option data-url="/?selected_currency=sar">SAR</option>
                          <option data-url="/?selected_currency=usd">USD</option>		
                        </select> */}
                      </div>
                    </li> 
                    <li className='nav-item nav-link scrollto'>
                      <div class="switcher notranslate mt-2">
                        <div class="selected">
                          <a href="#" onClick={toggleDropdown1} class='open'>
                          <img src={en} height="16" width="16" alt="en" /> English</a>
                        </div>
                        <div class="option">
                          <a href="#" onclick="doGTranslate('en|ar');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Arabic" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/ar.png" height="16" width="16" alt="ar" /> Arabic</a>
                          <a href="#" onclick="doGTranslate('en|zh-CN');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Chinese (Simplified)" class="nturl" >
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/zh-CN.png" height="16" width="16" alt="zh-CN" /> Chinese (Simplified)</a>
                          <a href="#" onclick="doGTranslate('en|nl');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Dutch" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/nl.png" height="16" width="16" alt="nl" /> Dutch</a>
                          <a href="#" onclick="doGTranslate('en|en');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="English" class="nturl selected">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/en.png" height="16" width="16" alt="en" /> English</a>
                          <a href="#" onclick="doGTranslate('en|fr');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="French" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/fr.png" height="16" width="16" alt="fr" /> French</a>
                          <a href="#" onclick="doGTranslate('en|de');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="German" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/de.png" height="16" width="16" alt="de" /> German</a>
                          <a href="#" onclick="doGTranslate('en|it');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Italian" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/it.png" height="16" width="16" alt="it" /> Italian</a>
                          <a href="#" onclick="doGTranslate('en|pt');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Portuguese" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/pt.png" height="16" width="16" alt="pt" /> Portuguese</a>
                          <a href="#" onclick="doGTranslate('en|ru');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Russian" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/ru.png" height="16" width="16" alt="ru" /> Russian</a>
                          <a href="#" onclick="doGTranslate('en|es');jQuery('div.switcher div.selected a').html(jQuery(this).html());return false;" title="Spanish" class="nturl">
                          <img src="https://moroccoxcursion.com/wp-content/plugins/gtranslate/flags/16/es.png" height="16" width="16" alt="es" /> Spanish</a>
                        </div>
                      </div>
                    </li>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <nav className="navbar-expand-lg navbar bg-light text-dark" style={{backgroundColor:'white', opacity:'0.9', zIndex:'-1'}}>
          <div className="container">
            <a className="navbar-brand p-1" href="." >
              <img src={logo} alt="Logo" height="60" className="d-inline-block align-text-top" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> 
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div className="offcanvas-header">
                  <a className="navbar-brand" href=".">
                    <img src={logo} alt="Logo" width="90" height="60" className="d-inline-block align-text-top" />
                  </a>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav navbar-link justify-content-left flex-grow-1 pe-3 column-gap-2">
                  <li className='nav-item nav-link active'>
                    <NavLink className='nav-link' to='/'> Home </NavLink>
                  </li>
                  <li className='nav-item nav-link active'>
                    <NavLink className='nav-link' to='/activities'> Excursions </NavLink>
                  </li>
                  {/* <li className='nav-item nav-link scrollto d-flex'>
                    <NavLink className='nav-link'  to='/search-tour'>  Packages {' '} </NavLink>
                  </li> */}
                  {/* <li className='nav-item dropdown nav-link scrollto'>
                    <NavLink className='nav-link' to='/transfers'> Transfers  </NavLink>
                    {isDropdownOpen2 && (
                      <ul>
                        {dropdownCat.map((item, index) => (
                          <li key={index} className='mb-3'>
                            <h6
                              onClick={() => FindPackageDetail(item.id)}
                              className='dropdown-nav-link '
                            >
                              {item.title}
                            </h6>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li> */}
                  <li className='nav-item nav-link scrollto d-flex'>
                    <NavLink className='nav-link' to='/about-us'> About Us </NavLink>
                  </li>
                  <li className='nav-item nav-link scrollto'>
                    <NavLink className='nav-link' to='/contact-us'> Contact Us </NavLink>
                    {/* <span className='submenu-toggle'>  <FontAwesomeIcon icon={faAngleRight} /> </span> */}
                  </li>
                  <li className='nav-item nav-link scrollto'>
                    <NavLink className='nav-link' to='/blog'> Blog  </NavLink>
                  </li>
                </ul>
                <li className='nav-item nav-link scrollto'>
                  <NavLink className='nav-link my-2' to='...'>
                  <span className='text-dark'>  <FontAwesomeIcon icon={faSearch} /> </span>
                  </NavLink>
                </li>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
  function toggleDropdown1() {
    var dropdown = document.querySelector('.switcher .option');
    dropdown.style.display = (dropdown.style.display === 'none' || dropdown.style.display === '') ? 'block' : 'none';
  }
}


export default Navbar
