import React,{useState,useEffect} from 'react'
import tokiyo from '../../Images/Carousal/marakech.webp'
import tokiyo1 from '../../Images/Carousal/marakech1.webp'
import { useSelector } from "react-redux";
import TopExcursions from '../../Pages/Activities/TopExcursions'
import { useNavigate } from "react-router-dom";
import { CustomerDomainName,ApiEndPoint,ActivityToken } from "../GlobalData/GlobalData";

function PopularCities(){
  const ActivityListing = useSelector((state) => state.hotels.ActivityListing);
  const [newActivityListing, setNewActivityListing] = useState(ActivityListing?.tours || getDefaultTopTours());
  useEffect(() => {
    // Ensure ActivityListing and tours are defined before updating the state
    if (ActivityListing?.tours) {
      setNewActivityListing(ActivityListing.tours);
    } else {
      // If ActivityListing or tours is undefined, set default top tours
      setNewActivityListing(getDefaultTopTours());
    }
  }, [ActivityListing]);

  function getDefaultTopTours() {
    // Provide some default top tours data when ActivityListing is not available
    return [
      { id: 1, name: 'Top Tour 1' },
      { id: 2, name: 'Top Tour 2' },
      { id: 3, name: 'Top Tour 3' },
    ];
  }

  const [isLoading, setIsLoading] = useState(false);
  var Domainpath=CustomerDomainName();
  var navigation=useNavigate();
  const showDetail=(id)=>{
    navigation(`/activity_details/${id}`);
  };

  return (
    <>
       <TopExcursions newActivityListing={newActivityListing} />
      {/* <div className='container excursion mt-5'>
        <h1> MOROCCO TOP <span className='green'>EXCURSIONS</span> </h1>
        <div className='row mt-4'>
          <div className='column'>
            <div className='image-contain m-2'>
              <img src={america} className='inner-img' alt='America' />
              <h6 className='green m-3'>IMPERIAL CITIES TOUR</h6>
              <div className='d-flex mx-3 mb-3'>
                <span className='col-2' style={{color:'rgb(16 73 129)'}}><b>5/5</b></span> 
                <p className='col-6'><b>Excellent </b>(3 Reviews)</p>
                <div className='col-4' style={{textAlign:'right'}}>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                </div>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='image-contain m-2'>
              <img src={canada} alt='canada' />
              <h6 className='green m-3'>LUXURY DESERT TOUR</h6>
              <div className='d-flex mx-3 mb-3'>
                <span className='col-2' style={{color:'rgb(16 73 129)'}}><b>5/5</b></span> 
                <p className='col-6'><b>Excellent </b>(3 Reviews)</p>
                <div className='col-4' style={{textAlign:'right'}}>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                </div>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='image-contain m-2'>
              <img src={italy} alt='italy' />
              <h6 className='green m-3'>HORSEBACK RIDING MOROCO</h6>
              <div className='d-flex mx-3 mb-3'>
                <span className='col-2' style={{color:'rgb(16 73 129)'}}><b>5/5</b></span> 
                <p className='col-6'><b>Excellent </b>(3 Reviews)</p>
                <div className='col-4' style={{textAlign:'right'}}>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                 <i className='mx-1' style={{color:'#ed6f15'}}><FontAwesomeIcon icon={faStar} /></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid p-0">
        <section className="mobile">
          <div className="row p-5 sm2">
            <div className="col-md-6">
                <h1 className='py-4 mt-4'>Great opportunity<span className='green'> to Travel Morocco</span></h1>
                <p className='mt-3 mx-1'>With our designed activities, you will get to know more about the country and its traditional festivals. 
                    You will not miss any of its beauty spots when you booking with Morocco Excursions. 
                 </p>
                <p className='mt-3 mx-1'>The silence of the deserts will help you figure out the sound of your heart. 
                  Listen to it carefully, it says what you always wanted to hear about, yes heart has felt every pain and it’s time to get relief from them.
                 Travelling is like feeding your soul; it will always fall for more wandering temptations.</p>
            </div>
            <div className="col-md-6 d-flex col-sm-12">
              <div className='mx-4 sm'>
              <img src={tokiyo} className="l-img" alt="mobile" />
                <h1 className='green text-center'>20+</h1>
                <h6 className='text-dark mt-2'>LOCATIONS WORLD WIDE</h6>
              </div>
              <img src={tokiyo1} className="r-img" alt="mobile" />
            </div>
          </div>
        </section>
      </div>
      <div className="container-fluid px-0 py-5" style={{backgroundColor:'rgb(250, 245, 245)'}}>
         <section className="journey">
              <h1 className='text-light' style={{fontWeight:'500'}}>Morocco Deserts Tours</h1>
              <p className='mt-5'>These deserts explains you the complete history of Morocco, it's not only 
                about blazing heat but represent the culture diversity of Morocco too. Other than deserts,
                its markets are also a great fun; make it like a game of treasure hunt. In the medinas you will 
                love to be a part of the shopping tours. Marrakech have colorful street, tour of streets can 
                be done on the bike while you can book Marrakech excursions and Marrakech desert tours
                 by availing our services.</p>
              <p className='mt-3'> The experience of motorbike is amazing. Diversity of a culture makes this city special because 
                 anyone who visits it feels like being home and so you know that home is where the heart is. 
                 And I am sure when you will make a tour to Morocco, 
                 your heart would be stolen by the end of it, it is all about the magic of the Morocco.</p>
         </section>
      </div>
    </>
  )
}

export default PopularCities
