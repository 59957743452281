import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faPlaneDeparture,
  faPlaneArrival,
  faCheck,
  faHeart
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Axios from 'axios'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading/Loader'
import {
  CustomerDomainName,
  Hotelapitoken,
  ApiEndPoint
} from '../GlobalData/GlobalData'
function PackageDetailCard ({ filterData }) {
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [FilterData, setFilterData] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const imageurl = CustomerDomainName()
  const ToursDetail = useSelector(state => state.hotels.toursdetail)
  var apiendpoint = ApiEndPoint();

  const fetchViewDetail = async (event, id, otherprovider, name) => {
    event.preventDefault();
    var apitoken = Hotelapitoken();
    setLoading(true);
  
    if (otherprovider === 0) {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: ''
      };
    } else {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: otherprovider
      };
    }
  
    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      );
  
      const currentMonth = new Date().getMonth();
      const filteredData = response.data.filter(item => {
        const itemMonth = new Date(item.date).getMonth();
        return itemMonth === currentMonth;
      });
    
      // Reverse the order to have the most recent dates first
      Dispatch(ViewTourDetail(filteredData));
    
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    DataFilter()
  }, [filterData])

  const DataFilter = () => {
    if (filterData !== '') {
      if (filterData.type === 'price') {
        const filteredTours = ToursDetail.tours.filter(tour => {
          const price = tour.quad_grand_total_amount
          return price >= filterData.min && price <= filterData.max
        })
        setFilterData(filteredTours)
        setShowFilter(false)
      } else if (filterData.type === 'Stars') {
        if (
          filterData.rating1 === '0' &&
          filterData.rating2 === '0' &&
          filterData.rating3 === '0' &&
          filterData.rating4 === '0' &&
          filterData.rating5 === '0'
        ) {
          setFilterData(ToursDetail.tours)
          setShowFilter(false)
        } else {
          const filteredTours = ToursDetail.tours.filter(tour => {
            const hotelRating = tour.starts_rating
            if (hotelRating !== '') {
              return Object.keys(filterData).some(
                ratingKey =>
                  Number(hotelRating) === Number(filterData[ratingKey])
              )
            }
          })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
      }else if (filterData.type === 'airport') {
        var length= Object.keys(filterData).length;
        if(length === 1){
          setFilterData(ToursDetail.tours)
          setShowFilter(false)
        }else{
          const filteredTours = ToursDetail.tours.filter(tour => {
            var flightdetail=JSON.parse(tour.flights_details);
           
            var name=flightdetail[0].departure_airport_code;
  
            return Object.keys(filterData).some(
              ratingKey =>
              name === filterData[ratingKey]
            )        })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
       
      }
    }
  };

  console.log(ToursDetail);
  return (
    <>
      {loading && <Loading />}
      {showFilter ? (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
               {[...ToursDetail.tours].reverse().map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                 <div key={index} className='mb-4' >
                   <div className='wow animate__animated animate__slideInRight mt-2'
                      data-wow-duration="1s"
                      data-wow-delay="0.2s">
                      <div className='row parent_row' style={{backgroundColor:'white'}}>
                        <div className='col-md-4 p-0 position-relative hh'>
                              <img
                                className='tour-img'
                                src={
                                  imageurl +
                                  'public/uploads/package_imgs/' +
                                  item.tour_banner_image
                                }
                                alt=''
                              />
                              <div class="wishlist"> <FontAwesomeIcon icon={faHeart} /> </div>
                              <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='a-card-price'>
                                {item.title}
                              </a>
                              <div className='row my-2  p-car-departure hover1'>
                                <p>
                                  <i className='fa-solid fa-check'>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </i>{' '}
                                  Available Spaces{' '} ({item.no_of_pax_days})&nbsp;
                                </p>
                              </div>
                              <div className='col-md-12 col-sm-5 my-2 p-car-departure hover2'>
                                  <p>  <i className='fa-solid fa-plane-departure'>
                                    <FontAwesomeIcon icon={faPlaneDeparture} />
                                  </i>{' '}
                                  {/* Departure From{' '} */}
                                {flightDetails && flightDetails[0] ? flightDetails[0].departure_airport_code : 'N/A'} </p>
                              </div>
                              <div className='row my-2 p-car-departure hover3 px-1'>
                                <span>
                                  <i className='fa-solid fa-plane-arrival'>
                                    <FontAwesomeIcon icon={faPlaneArrival} />
                                  </i>{' '}
                                  {/* Return Date{' '} */}
                                  {' '}{moment(item.end_date).format('LL')} &nbsp;  </span>
                              </div>
                              <div className='mt-2 hover4'>
                                <i className='fa fa-moon-o' aria-hidden='true'></i>
                                <span className='tour_length'> {item.time_duration} Night </span>
                              </div>
                              {/* <p className='departure-date mt-2'>
                              <i className='fa-solid fa-plane-departure'>
                                <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                              </i>
                              {moment(item.start_date).format('LL')}{' '}
                            </p>  */}
                            {/* <div
                              id='map-container-google-2'
                              className='z-depth-1-half map-container py-0'
                              // style={{ height: '520' }}
                            >
                              <iframe
                                src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                              &q=Mecca+Saudi+Arabia'
                                frameborder='0'
                                allowfullscreen
                                className='py-0'
                              ></iframe>
                            </div> */}
                        </div>
                        <div className='col-md-6 p-4'>
                          <p className='card-star'>
                            {item.starts_rating === 'No_Rating' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              <>
                                {Array.from({
                                  length: parseInt(item.starts_rating, 10)
                                }).map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    {' '}
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))} <span style={{color:'GrayText'}}>({item.starts_rating}.0) </span>
                              </>
                            )}
                          </p>
                          <h5 className='card-title'>
                            <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='p-card-title'>
                              {item.title}
                            </a>
                          </h5>
                          <p className='tour-description mt-2' style={{color:'GrayText'}}>{item.content}</p>
                        </div>
                        <div className='col-md-2 p-3 pt-5' style={{borderLeft:'1px solid #dedede'}}>
                          <div className='price text-center p-card-price'>
                            <h6>
                              {item.currency_symbol}
                              <super>
                              {item.quad_grand_total_amount !== null
                              ? item.quad_grand_total_amount
                              : item.triple_grand_total_amount !== null
                              ? item.triple_grand_total_amount
                              : item.double_grand_total_amount !== null
                              ? item.double_grand_total_amount
                              : 'N/A'}  
                                </super> {/* <sub>PP</sub> */}
                            </h6>
                          </div>
                          <h6
                            onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                            className='text-center  p-view-detail mt-2'
                          >
                            View Details
                          </h6>
                          <a
                            onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                            className='btn btn-primary select-styling search-btn1 form-control mt-2 rounded-1'
                          >
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                 </div>
                  )
                })}
                <div className='my-3'>
                  <h5 className='flight-heading'>  Packages From Other Providers </h5>
                </div>
                {ToursDetail.other_providers_tours.map((provider, index) => (
                  <div className='row' key={index}>
                    {provider[0].map((item, index) => {
                      const flightDetails = JSON.parse(item.flights_details)
                      return (
                        <div key={index} className='mt-2'>
                          <div className='row parent_row' style={{backgroundColor:'white'}}>
                            <div className='col-md-4 p-0 position-relative hh'>
                                 <img
                                    className='tour-img mb-1'
                                    src={
                                      provider[1].webiste_Address +
                                      '/public/uploads/package_imgs/' +
                                      item.tour_banner_image
                                    }
                                    alt=''
                                  />
                              <div class="wishlist"> <FontAwesomeIcon icon={faHeart} /> </div>
                              <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='a-card-price'>
                                {item.title}
                              </a>
                                  <div className='row my-2 p-car-departure hover2 mx-1 px-0'>
                                    <p>
                                      <i className='fa-solid fa-plane-departure'>
                                        <FontAwesomeIcon
                                          icon={faPlaneDeparture}
                                        />
                                      </i>{' '}
                                      { flightDetails[0].departure_airport_code } &nbsp;
                                    </p>
                                  </div>
                                  <div className='row my-2 p-car-departure hover3'>
                                    <span>
                                      <i className='fa-solid fa-plane-arrival'>
                                        <FontAwesomeIcon icon={faPlaneArrival} />
                                      </i>{' '}
                                      {moment(item.end_date).format('LL')} &nbsp;
                                    </span>
                                  </div>
                                  <div className='row my-2  p-car-departure hover1'>
                                    <p>
                                      <i className='fa-solid fa-check'>
                                        <FontAwesomeIcon icon={faCheck} />
                                      </i>{' '}
                                      Available Spaces{' '}({item.no_of_pax_days})&nbsp;
                                    </p>
                                  </div>
                                <div className='mt-2 hover4'>
                                  <i
                                    className='fa fa-moon-o'
                                    aria-hidden='true'
                                  ></i>
                                  <span className='tour_length'>
                                    {item.time_duration} Night
                                  </span>
                                </div>
                              {/* <div
                                id='map-container-google-2'
                                className='z-depth-1-half map-container py-0'
                                // style={{ height: '520' }}
                              >
                                <iframe
                                  src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                                &q=Mecca+Saudi+Arabia'
                                  frameborder='0'
                                  allowfullscreen
                                  className='py-0'
                                ></iframe>
                              </div> */}
                            </div>
                            <div className='col-md-6 px-3 py-5'>
                              <p className='card-star'>
                                {item.starts_rating === 'No_Rating' ? (
                                  <span className='fw-bold'>No Rating</span>
                                ) : (
                                  <>
                                    {Array.from({
                                      length: parseInt(item.starts_rating, 10)
                                    }).map((_, index) => (
                                      <i key={index} className='fa fa-star'>
                                        {' '}
                                        <FontAwesomeIcon icon={faStar} />
                                      </i>
                                    ))}( {item.starts_rating}.0)
                                  </>
                                )}
                              </p>
                              <h5 className='card-title'>
                                <a href='#' className='p-card-title'> {item.title} </a>
                              </h5>
                              <p className='tour-description mt-2' style={{color:'GrayText'}}>{item.content}</p>
                              {/* <h6 className='departure-date'>
                                <i className='fa-solid fa-plane-departure'>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                                </i>
                                {moment(item.start_date).format('LL')}{' '}
                              </h6> */}
                            </div>
                            <div className='col-md-2 pt-5' style={{borderLeft: '1px solid rgb(222, 222, 222)'}}>
                              <div className='price text-center p-card-price'>
                                <h6>
                                  {item.currency_symbol}
                                  <super>
                                    {item.double_grand_total_amount}
                                  </super>{' '}
                                  {/* <sub>PP</sub> */}
                                </h6>
                              </div>
                              <h6 className='text-center p-view-detail mt-2'>
                                <NavLink className='nav-link' to='/view-detail'>
                                  View Details
                                </NavLink>
                              </h6>
                              <a className='btn mt-2 btn-primary select-styling search-btn1 form-control rounded-1'>
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
          </div>
        </div>
      ) : (
        <div className='filter-page__content'>
          <div className='filter-item-wrapper' id='tours_filter'>
            <div className='row'>
                {FilterData.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                 <div  key={index} className='row mb-4'>
                    <div className='mt-2'>
                      <div className='row parent_row' style={{backgroundColor:'white'}}>
                        <div className='col-md-4 p-0 position-relative hh'>
                            <img
                              className='tour-img mb-1'
                              src={
                                imageurl +
                                'public/uploads/package_imgs/' +
                                item.tour_banner_image
                              }
                              alt=''
                            />
                             <div class="wishlist"> <FontAwesomeIcon icon={faHeart} /> </div>
                              <a  onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='a-card-price'>
                                {item.title}
                              </a>
                              <div className='img_hover'>
                                <div className='row f-13 px-4'>
                                  <div className='row my-2 p-car-departure'>
                                    <p>
                                      <i className='fa-solid fa-plane-departure'>
                                        <FontAwesomeIcon icon={faPlaneDeparture} />
                                      </i>{' '}
                                      {flightDetails[0].departure_airport_code} &nbsp;
                                    </p>
                                  </div>
                                  <div className='row my-2 p-car-departure'>
                                    <p>
                                      <i className='fa-solid fa-plane-arrival'>
                                        <FontAwesomeIcon icon={faPlaneArrival} />
                                      </i>{' '}
                                      {moment(item.end_date).format('LL')} &nbsp;
                                    </p>
                                  </div>
                                  <div className='row my-2  p-car-departure'>
                                    <p>
                                      <i className='fa-solid fa-check'>
                                        <FontAwesomeIcon icon={faCheck} />
                                      </i>{' '}
                                      Available Spaces{' '}({item.no_of_pax_days})&nbsp;
                                    </p>
                                  </div>
                                </div>
                                <div className='my-2 px-4'>
                                  <i className='fa fa-moon-o' aria-hidden='true'></i>
                                  Tour Length{' '}
                                  <span className='tour_length'>
                                    {item.time_duration} Night
                                  </span>
                                </div>
                              </div>
                            {/* <div
                              id='map-container-google-2'
                              className='z-depth-1-half map-container'
                              style={{ height: '500' }}
                            >
                              <iframe
                                src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                              &q=Mecca+Saudi+Arabia'
                                frameborder='0'
                                allowfullscreen
                              ></iframe>
                            </div> */}
                        </div>
                        <div className='col-md-6 p-3'>
                          <p className='card-star'>
                            {item.starts_rating === 'No_Rating' ? (
                              <span className='fw-bold'>No Rating</span>
                            ) : (
                              <>
                                {item.starts_rating}.0
                                {Array.from({
                                  length: parseInt(item.starts_rating, 10)
                                }).map((_, index) => (
                                  <i key={index} className='fa fa-star'>
                                    {' '}
                                    <FontAwesomeIcon icon={faStar} />
                                  </i>
                                ))}
                              </>
                            )}
                          </p>
                          <h5 className='card-title'>
                            <a onClick={event => fetchViewDetail(event, item.id,0,item.title)} className='p-card-title'>
                              {item.title}
                            </a>
                          </h5>
                          {/* <h6 className='departure-date'>
                            <i className='fa-solid fa-plane-departure'>
                              <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                            </i>
                            {moment(item.start_date).format('LL')}{' '}
                          </h6> */}
                          <p className='tour-description mt-2'>{item.content}</p>
                        </div>
                        <div className='col-md-2 pt-5' style={{borderLeft:'1px solid #dedede'}}>
                          <div className='price text-center p-card-price'>
                            <h6>
                              {item.currency_symbol}
                              <super> {item.quad_grand_total_amount}</super> {/* <sub>PP</sub> */}
                            </h6>
                          </div>
                          <h6
                            onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                            className='text-center  p-view-detail mt-2'
                          >
                            View Details
                          </h6>
                          <a
                          onClick={event => fetchViewDetail(event, item.id,0,item.title)}
                            className='btn btn-primary select-styling search-btn1 form-control mt-2'
                          >
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>  
                  )
                })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PackageDetailCard
