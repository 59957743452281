import React from "react";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faEnvelope, faF, faG, faI, faMobileScreenButton, faP, faPhone, faPlay, faT, faV, faVideo, faY} from '@fortawesome/free-solid-svg-icons'
import fb from '../../Images/Logo/fb_logo.png'
import insta from '../../Images/Logo/insta.png'
import linkedin from '../../Images/Logo/linkedin_logo.png'
import twitter from '../../Images/Logo/twitter_logo.png'
import logo from '../../Images/Logo/logo.png';

function Footer(){
    return(
    <>
     <footer class="revealed">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h3>Need help?</h3>
                    <a href="tel://004542344599" id="phone">
                      <div className="row">
                        <div className="col-1"><i  style={{fontSize:'24px'}}><FontAwesomeIcon icon={faPhone} /></i></div>
                        <div className="col-4 mx-2"> 00212524438627</div>
                      </div>
                    </a>
                    <a href="mailto:help@citytours.com" id="email_footer">
                      <div className="row">
                        <div className="col-1"><i style={{fontSize:'24px'}}><FontAwesomeIcon icon={faEnvelope} /></i></div>
                        <div className="col-4 mx-2"> info@morrocoexcursion.com</div>
                      </div>
                    </a>
                </div>
                <div class="col-md-3">
                    <h3>About</h3>
                    <ul>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">FAQ</a></li>
                        <li><a href="#">Login</a></li>
                        <li><a href="#">Register</a></li>
                        <li><a href="#">Terms and condition</a></li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>Discover</h3>
                    <ul>
                        <li><a href="#">Community blog</a></li>
                        <li><a href="#">Tour guide</a></li>
                        <li><a href="#">Wishlist</a></li>
                        <li><a href="#">Gallery</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h3>Settings</h3>
                    <div class="styled-select">
                        <select name="lang" id="lang">
                            <option value="English" selected="">English</option>
                            <option value="French">French</option>
                            <option value="Spanish">Spanish</option>
                            <option value="Russian">Russian</option>
                        </select>
                    </div>
                    <div class="styled-select">
                        <select name="currency" id="currency">
                            <option value="USD" selected="">USD</option>
                            <option value="EUR">EUR</option>
                            <option value="GBP">GBP</option>
                            <option value="RUB">RUB</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div id="social_footer">
                        <ul>
                            <li><a href="#"><i class="icon-facebook"><FontAwesomeIcon icon={faF} /></i></a></li>
                            <li><a href="#"><i class="icon-twitter"><FontAwesomeIcon icon={faT} /></i></a></li>
                            <li><a href="#"><i class="icon-google"><FontAwesomeIcon icon={faG} /></i></a></li>
                            <li><a href="#"><i class="icon-instagram"><FontAwesomeIcon icon={faI} /></i></a></li>
                            <li><a href="#"><i class="icon-pinterest"><FontAwesomeIcon icon={faP} /></i></a></li>
                            <li><a href="#"><i class="icon-vimeo"><FontAwesomeIcon icon={faV} /></i></a></li>
                            <li><a href="#"><i class="icon-youtube-play"><FontAwesomeIcon icon={faPlay} /></i></a></li>
                        </ul>
                        <p className="mb-3 text-center">@ Morocco Excursions 2024</p>
                    </div>
                    {/* © */}
                </div>
            </div>
        </div>
    </footer>
    </>        
    );
}

export default Footer;