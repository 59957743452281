import React from 'react'
import america from '../../Images/Hotels/america.jpeg'
import australia from '../../Images/Hotels/australia.jpeg'
import canada from '../../Images/Hotels/canada.jpeg'
import hongkong from '../../Images/Hotels/hongkong.jpeg'
import italy from '../../Images/Hotels/italy.jpeg'
import paris from '../../Images/Hotels/paris.jpeg'
import saudia from '../../Images/Hotels/saudia.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faLandmark, faPersonWalking } from '@fortawesome/free-solid-svg-icons'

function GlobeSection () {
  return (
    <>
    <div class="vc_row wpb_row vc_row-fluid margin_60 inner-container" style={{backgroundColor:'rgb(250, 245, 245)'}}>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 ">
            <div>
              <div class="main_title">
                  <h2>Some <b>good</b> reasons</h2>
                </div>
            </div> 
            <div class="vc_row wpb_row vc_inner row">
              <div class="wow zoomIn wpb_column col-sm-12 col-md-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="ct-icon-box  feature_home">
                      <i class="icon_set_1_icon-44"><FontAwesomeIcon icon={faLandmark} /></i>
                      <h3 style={{fontWeight:'500'}}>Languages</h3>
                      <p class="mb-3">Weather its English,Arabic,French or any other us at morocco xcursions are fluent in all.</p>
                      <p><a class="btn_1 outline" href="https://moroccoxcursion.com/about-us/">Read more</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wow zoomIn wpb_column col-sm-12 col-md-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="ct-icon-box  feature_home">
                      <i class="icon_set_1_icon-30"><FontAwesomeIcon icon={faPersonWalking} /></i>
                      <h3 style={{fontWeight:'500'}}>Customer</h3>
                      <p class="mb-3">In a short space of time we have a great customer base. The avocation we have on making sure you feel the ambiance of a great morocco trip.</p>
                      <p><a class="btn_1 outline" href="https://moroccoxcursion.com/about-us/">Read more</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wow zoomIn wpb_column col-sm-12 col-md-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="ct-icon-box  feature_home">
                      <i class="icon_set_1_icon-57"><FontAwesomeIcon icon={faHeadset} /></i>
                      <h3 style={{fontWeight:'500'}}>Support</h3>
                      <p class="mb-3">We have a dedicated customer support team available 24 hrs a day. We are here to answer any questions be it location, Pricing or selection</p>
                      <p><a class="btn_1 outline" href="https://moroccoxcursion.com/about-us/">Read more</a> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
   </div>
      {/* <div className='container-fluid mobile p-0'>
        <div className='row'>
          <div className='section-title'>
            <h1 className='green'>Tours</h1>
          </div>
        </div>
        <div className='row pt-3 px-5' style={{color:'black'}}>
          <div className='column'>
            <img src={australia} className='inner-img' alt='America' />
            <div style={{textAlign:'center'}}>
              <h4 className='mt-3 mb-2' style={{fontWeight:'500'}}>MOROCOO TOUR</h4>
              <p>Explore More</p>
            </div>
          </div>
          <div className='column'>
              <img src={canada} alt='canada' />
              <div style={{textAlign:'center'}}>
                 <h4 className='mt-3 mb-2' style={{fontWeight:'500'}}>MOROCOO TOUR</h4>
                  <p>Explore More</p>
              </div>
          </div>
          <div className='column'>
              <img src={italy} alt='italy' />
              <div style={{textAlign:'center'}}>
                 <h4 className='mt-3 mb-2' style={{fontWeight:'500'}}>MOROCOO TOUR</h4>
                  <p>Explore More</p>
              </div>
          </div>
          <div className='column img-grid'>
              <img src={saudia} alt='saudia' />
              <div style={{textAlign:'center'}}>
                 <h4 className='mt-3 mb-2' style={{fontWeight:'500'}}>MOROCOO TOUR</h4>
                  <p>Explore More</p>
              </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default GlobeSection
