import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/img5.webp'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/img2.webp'
import img3 from '../../Images/Carousal/img3.webp'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/img4.webp'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import Navbar from "../../Components/Navbar/Navbar";
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Home/2.jpg'
import Hotels from '../../Components/Hotel/Hotels'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import GlobeSection from '../../Components/Hotel/GlobeSection';
import PopularCities from '../../Components/MorePackages/PopularCities';
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
function Home () {

  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(false)
  const [modalOpen,setModalOpen]=useState(false);
  const navigate=useNavigate();
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);

  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };

  const hideadd = () => {
    setShowbottomAdd(false)
  };
  const gotoHajjPage=()=>{
    navigate('/hajj');
  }

  return (
    <>
      <Helmet>
        <title>Morocco Excursions</title>
      </Helmet>
      {isLoading && <Loader />}
      {/* <Layout> */}
        <div className='overlay-container' style={{height:'600px'}}>
          <Navbar />
          <Carousel className='carousel-container' style={{height:'100%'}}>
            <Carousel.Item style={{height:'100%'}}>
              <img className='d-block w-100' style={{height:'100%'}} src={img1} alt='First slide' />
              <div class="carousel-caption d-none d-md-block mb-2">
                <h2>Tailored For You Let our experts plan your trip to Morroco.</h2>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{height:'100%'}}>
              <img className='d-block w-100' src={img2} style={{height:'100%'}} alt='First slide' />
              <div class="carousel-caption d-none d-md-block mb-3">
                <h2>We speak your language</h2>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{height:'100%'}}>
              <img className='d-block w-100' src={img3} style={{height:'100%'}} alt='First slide' />
              <div class="carousel-caption d-none d-md-block mb-3">
                <h2>VIP Travel Services</h2>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{height:'100%'}}>
              <img className='d-block w-100' src={img4} style={{height:'100%'}} alt='First slide' />
              <div class="carousel-caption d-none d-md-block mb-2">
                <h2>We are ministry of tourism Approved, book with peace</h2>
              </div>
            </Carousel.Item>
          </Carousel>
          <SearchBar />
        </div>
        <PopularCities />
        <GlobeSection/>
        <Footer />
        {/* <Packages /> */}
        {/* <Hotels/> */}
        {/* <MorePackages /> */}
      {/* </Layout> */}
      {bottomAdd && (
        <div class='phone-only'>
          <div class='container position-relative'>
            <div class='phone-only-img'>
              <img
                class='phone-only-agent'
                width='68'
                height='68'
                alt='Call us at0121 777 2522'
                src={agentpic}
              />
            </div>
            <b>
              Looking for last-minute deals? Just give us a call to get
              phone-only deals!
            </b>
            <br />
            Call us at
            <a
              title='0121 777 2522'
              role='button'
              class='phonelink px-1'
              href='tel:0121 777 2522'
            >
              <span class='phone-number'>0121 777 2522</span>
            </a>
            and get assistance.
            <span class='cross-icon' onClick={hideadd}>
              ╳
            </span>
          </div>
        </div>
      )}
      <Modal isOpen={modalOpen} className='home_modal' toggle={toggleModal} >
        <ModalHeader toggle={toggleModal}>Al Hijaz Tours</ModalHeader>
        <ModalBody>
        <img
                class='phone-only-agent'
                width='auto'
                height='auto'
                onClick={gotoHajjPage}
                alt='Call us at 0121 777 2522'
                src={adimage}
              />
                <div className='text-center   mt-2 align-self-center'>
                                <p className='phone-only-agent' style={{color:'#0000ffa1'}} onClick={gotoHajjPage}>Click For Detail</p>
                            </div>
                            
        </ModalBody>
      </Modal>
    </>
  )
}

export default Home
