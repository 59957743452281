import React,{useState,useEffect} from 'react'
import alaqsa from '../../Images/Packages/AlAqsa-package.jpg'
import tokiyo from '../../Images/Carousal/1.jpg'
import umrah from '../../Images/Packages/umrah-package.jpg'
import Axios from 'axios'
import america from '../../Images/Hotels/america.jpeg'
import australia from '../../Images/Hotels/australia.jpeg'
import canada from '../../Images/Hotels/canada.jpeg'
import hongkong from '../../Images/Hotels/hongkong.jpeg'
import italy from '../../Images/Hotels/italy.jpeg'
import paris from '../../Images/Hotels/paris.jpeg'
import saudia from '../../Images/Hotels/saudia.jpeg'
import spain from '../../Images/Hotels/spain.jpeg'
import { ActivitiesListing } from "../../Redux/Actions/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faInfoCircle,
  faGlobe,
  faCalendarCheck,
  faHeart,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { CustomerDomainName,ApiEndPoint,ActivityToken } from "../../Components/GlobalData/GlobalData";

function TopExcursions(){
  // const [isLoading, setIsLoading] = useState(false);
  var Domainpath=CustomerDomainName();
  var navigation=useNavigate();
  const[list, setList]=useState('');

  useEffect(() => {
    fetch();
    // console.log(list); // This will log the updated data when the state changes
  }, [list]);
 
  const fetch=async()=>{
    var endpoint=ApiEndPoint();
    var token=ActivityToken();
    var data={
      'token':token,
      'start_dates' : "",
  }
    try {
      const response = await Axios.post(endpoint + '/api/search_activities_react', data,{
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      const sortedTours = response.data.tours.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
      const latestThreeTours = sortedTours.slice(0, 3);
      setList(latestThreeTours);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const showDetail=(id)=>{
    navigation(`/activity_details/${id}`);
  };
  console.log(list);
  return (
    <>
    
      <div className='container-fluid excursion p-5' style={{backgroundColor:'rgb(250, 245, 245)'}} >
        <h1 style={{fontSize:'32px'}}> MOROCCO TOP <span className='green'>EXCURSIONS</span> </h1>
        <h4 style={{textAlign:'center', fontWeight:'400'}}>Experience the beauty of Morocco with Morocco Excursions.</h4>
        <p className='mt-2'>Travelling is the best way to learn because you get experience in many ways. Morocco is one of the countries which are like a treat for the eyes of travelers. 
          It’s not only about landscapes and streets but the amenities. The country is beautiful in its own way. You might have visited few famous places in other countries of Europe but this place is to fall in love with. 
          It’s been inspiring many foreigners from years by its fascinating colors and the energy it have in its air. The culture of Morocco is combined by Arab, 
          African and Europe cultures but they are quite traditional about their values and you can explore it through Morocco Tour Package.</p>
          <div className='filter-page__content mt-4'>
            <div className='filter-item-wrapper' id='tours_filter'>
              <div  className='row'>
                {Array.isArray(list) && list.map((item,index)=>(
                  <div key={index} className='col-md-4'>
                     <div className='image-contain' style={{backgroundColor:'white'}}>
                        <div className="" style={{ height: '250px' }} >
                           <img
                            className='tour-img'
                            src={Domainpath+'/public/images/activites/'+item.featured_image}
                            alt=''
                            />
                            {/* <div class="wishlist"> <FontAwesomeIcon icon={faHeart} /> </div> */}
                            {/* <a href="..." onClick={()=>showDetail(item.id)} className='a-card-price'>
                              {list.title}
                            </a> */}
                         </div>
                        <div className='' style={{ height: '250px' }} >
                          <div className='item-from p-3'>
                            <h5 className='card-title mb-2' style={{fontWeight:'500'}}>
                            <a
                            onClick={()=>showDetail(item.id)}
                              className=' green'
                            >
                            {item.title}
                            </a>
                            </h5>
                            <div className='d-flex mb-1'>
                              <span className='col-2' style={{color:'rgb(16 73 129)'}}><b>5/5</b></span> 
                              <p className='col-7 text-center'><b>Excellent </b>(3 Reviews)</p>
                              <div className='col-3' style={{textAlign:'right'}}>
                              <p className='card-star'>
                                {item.starts_rating === '' ? (
                                  <span className='fw-bold'>No Rating</span>
                                ) : (
                                  Array(item.starts_rating )
                                    .fill(0)
                                    .map((_, index) => (
                                      <i key={index} className='fa fa-star'>
                                        <FontAwesomeIcon icon={faStar} />
                                      </i>
                                    ))
                                )}
                                {/* (Activity Hours : {item.duration}) */}
                              </p>
                              </div>
                            </div>
                            <div className='price row p-card-price mt-3'>
                            <div className="col-9">
                              <h5 className='departure-date mt-1' style={{textAlign:'left', fontWeight:'500'}}>
                              {' '} <FontAwesomeIcon icon={faGlobe} />{' '}  {item.location}
                              </h5></div>
                            <div className="col-3"> 
                            <h5 className='mt-1'> {item.currency_symbol} {item.sale_price} </h5></div>
                            </div>
                            {/* <div class='row mt-1'>
                              <div class='single-feature-titles'>
                                <p style={{fontSize:'13px'}}>{item.activity_content}</p>
                              </div>
                            </div> */}
                          {/* <div class='item-address my-2' style={{ color: 'green' }}>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </i>{' '}
                            {item.activity_date}{' '}
                          </div> */}
                          <div className='my-2 text-center'> (Activity Hours : {item.duration})</div>
                          <button
                            onClick={()=>showDetail(item.id)}
                            className='btn btn-primary select-styling search-btn1 form-control my-2'
                          >
                            Book Now
                          </button>
                          <div className='text-center' style={{ fontSize: '11px' }}>
                            <FontAwesomeIcon
                              color='#bd1c1cc9'
                              icon={faInfoCircle}
                            />{' '}
                            Inclusive of VAT and Taxes
                          </div>
                          </div>
                        </div>
                     </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
      </div> 
    </>
  )
}

export default TopExcursions
