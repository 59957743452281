import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarDays, faCat, faCheck, faDungeon, faLocation, faMugHot, faPersonSwimming, faTelevision, faWheelchair, faWifi } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Carousal/marakech.webp'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
function HotelDetail () {
  const location = useLocation();
  const dispatch = useDispatch();
  const todayDate = new Date();
  var endpoint=ApiEndPoint();
  const { index } = location.state || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(true);
  const [baseCName, setBaseCName] = useState('GBP');
  const [counter, setCounter] = useState(0);

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([]);

  const navigate = useNavigate();
  const hoteldetail = useSelector(state => state.hotels.hoteldetail);
  const seleectedhotelfromlist = useSelector(
    state => state.hotels.hotels.hotels_list[index]
  );

  let { id } = useParams();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch);
  let Checkin = moment(ReduxSearchData.check_in);
  let checkout = moment(ReduxSearchData.check_out);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const latitude = hoteldetail.latitude;
  const longitude = hoteldetail.longitude;
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed';
 
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  };
  const handleButtonClick2 = (buttonName) => {
    setActiveButton(buttonName)
  };
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    if (selectedRooms.some(room => room.index === index)) {
      setCounter(counter - 1)
      // Check if there's a room with the same index in selectedRooms
      const updatedSelectedRooms = selectedRooms.filter(
        room => room.index !== index
      )
      const newroom = hotelSelectedRooms.filter(item => item.index !== index)
      // Update the selectedRooms state with the new array
      setSelectedRooms(updatedSelectedRooms)
      setHotelSelectedRooms(newroom)
    } else {
      if (counter < ReduxSearchData.room) {
        setCounter(counter + 1)
        const selectedroom = hoteldetail.rooms_options[index]
        const key = { rateKey: selectedroom.booking_req_id, index: index }
        const newSelectedRoom = {
          room_rate_key: JSON.stringify(key),
          rooms_qty: selectedroom.rooms_qty,
          index: index
        }
        setSelectedRooms([...selectedRooms, newSelectedRoom])
        setHotelSelectedRooms([...hotelSelectedRooms, key])
      } else {
        toast.error('Select the room according to the search criteria.', {
          position: toast.POSITION.TOP_RIGHT
      });
      }
    }

    // } else {
    //   alert(`You can only select ${ReduxSearchData.room} room(s).`);
    // }
  };
  const BookRoom = async () => {
    if(hotelSelectedRooms.length !==0){
    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms
    }
    const finaldata = {
      token:
        'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(seleectedhotelfromlist),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        hotel_address: hoteldetail.hotel_address,
        hotel_country: hoteldetail.hotel_country,
        hotel_city: hoteldetail.hotel_city,
        atitude: hoteldetail.latitude,
        longitude: hoteldetail.longitude
      }),
      req_index: index
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        endpoint+'/api/hotels/checkavailability',
        finaldata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      if(response.data.status==='error'){
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      return;
      };
      dispatch(fetchCheckoutDetail(response.data.hotels_data))
      navigate('/hotel_checkout')
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false);
      console.error('Error:', error)
    }
  }else{
    toast.error('Please Select Room First.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }
  };

  const renderPrice = (price) =>{
    const userData = localStorage.getItem('HotelCurrency');
    const Rates = JSON.parse(userData);
      if(userData !==null){
          if(CurrencyRates===undefined){
            const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price)).toFixed(0);
        
          }else{
            var select123 = CurrencyRates.selectedcurrency;
            const gbpprice = Rates.conversion_rates[baseCName];
            var baseprice1 = (Number(gbpprice) * Number(price)).toFixed(0);
            const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice2) * Number(baseprice1)).toFixed(0);
          }
          return baseprice
       }else{
        setShowPrice(false);
       }
  };
  const calculateMarkup = (price) => {
    if(Object.keys(hoteldetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(hoteldetail.admin_markup) !== 0)
   {
    if(hoteldetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(hoteldetail.admin_markup))/100;
    }else{
      markupprice= Number(hoteldetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hoteldetail.customer_markup) !== 0)
   {
    if(hoteldetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hoteldetail.customer_markup))/100;
    }else{
      markupprice= Number(hoteldetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
 
  return (
    <>
   
      {loading ? (
        <Loader />
      ) : (
        <Layout>
        <div>
          <ToastContainer/>
          <div className='contact-img'>
            <img src={bgimage} />
          </div>
          <nav className='navbar view-detail-navbar'>
            <div className='container'>
              <ul>
                <li>
                  <a
                    href='#section-1'
                    onClick={() => handleButtonClick2('section-1')}
                    className={activeButton === 'section-1' ? 'current' : ''}
                  >
                    Overview
                  </a>
                </li>
                <li>
                  <a
                    href='#section-2'
                    onClick={() => handleButtonClick2('section-2')}
                    className={activeButton === 'section-2' ? 'current' : ''}
                  >
                    Rooms
                  </a>
                </li>
                <li>
                  <a
                    href='#section-3'
                    onClick={() => handleButtonClick('section-3')}
                    className={activeButton === 'section-3' ? 'current' : ''}
                  >
                    Facilities
                  </a>
                </li>
                <li>
                  <a
                    href='#section-4'
                    onClick={() => handleButtonClick('section-4')}
                    className={activeButton === 'section-4' ? 'current' : ''}
                  >
                    Near By Place
                  </a>
                </li>
                <li>
                  <a
                    href='#section-5'
                    onClick={() => handleButtonClick('section-5')}
                    className={activeButton === 'section-5' ? 'current' : ''}
                  >
                    Location
                  </a>
                </li>
                <li>
                  <a
                    href='#section-6'
                    onClick={() => handleButtonClick('section-6')}
                    className={activeButton === 'section-6' ? 'current' : ''}
                  >
                    Review & Rating
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className='container mt-2 pb-5'>
            <section id='section-1' className='pt-2'>
              <div className='row mt-3'>
                <div className='col-md-8 px-4'>
                  <div className='row mt-5 pb-3 mb-3' style={{color:'GrayText', borderBottom:'2px solid black', textAlign:'center'}}>
                    <div className='col-md-5'>
                      <div className='row'>
                        <div className='col-4'>
                          <i class="fa-solid fa-building-columns" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faTelevision} /></i>
                          <p className='text-center'>Plasma TV</p>
                        </div>
                        <div className='col-4'>
                          <i class="fa-solid fa-building-columns" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faWifi} /></i>
                          <p className='text-center'>Free WiFi</p>
                        </div>
                        <div className='col-4'>
                          <i class="fa-solid fa-wheelchair" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faPersonSwimming} /></i>
                          <p className='text-center'>Poll</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-7'>
                      <div className='row'>
                        <div className='col-3'>
                          <i class="fa-solid fa-heart" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faMugHot} /></i>
                          <p className='text-center'>Breakfast</p>
                        </div>
                        <div className='col-3'>
                          <i class="fa-solid fa-cat" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faCat} /></i>
                          <p className='text-center'>Pet Allowed</p>
                        </div>
                        <div className='col-3'>
                          <i class="fa-solid fa-volume-high" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faWheelchair} /></i>
                          <p className='text-center'>Accessibility</p>
                        </div>
                        <div className='col-3'>
                          <i class="fa-solid fa-user-tie" style={{fontSize:'36px'}}><FontAwesomeIcon icon={faDungeon} /></i>
                          <p className='text-center'>Parking</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Images data={hoteldetail.hotel_gallery} />
                </div>
                <div className='col-md-4 image-container px-0'>
                  <div style={{backgroundColor:'#565151', color:'white'}}>
                     <h3 className='py-3 text-center'>- {hoteldetail.hotel_name} -</h3>
                  </div>
                  <div className='m-4'>
                    <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                      <FontAwesomeIcon icon={faLocation} />
                    </i>{' '}
                    {hoteldetail.hotel_address}
                  </div>
                  <div className='px-5'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-6 mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}> Hotel Type </h6>
                            <input type='text' className='mt-2 w-100' style={{height:'35px'}}  />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <span style={{ fontSize: '16px' }} class='title fw-bold'>  Minimum Stay </span>
                            <input type='text' value={daysBetween + ' ' + 'Night' } className='mt-2 w-100' style={{height:'35px'}}  />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-md-12 mt-1'>
                        <div class='single-tour-feature mb-3'>
                          <h6 style={{ fontSize: '16px' }}> 
                            <i class='fas fa-check'> <FontAwesomeIcon icon={faCalendarDays} /> </i> Check In 
                          </h6>
                          <input type='text' value={moment(ReduxSearchData.check_in).format('DD/MM/YYYY')} style={{height:'40px'}} 
                            placeholder={moment(ReduxSearchData.check_in).format('DD/MM/YYYY')} className='mt-2 w-100 p-1' />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-12 mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}>
                              <i class='fas fa-check'> <FontAwesomeIcon icon={faCalendarDays} /> </i> Check Out 
                            </h6>
                            <input type='text' value={moment(ReduxSearchData.check_out).format('DD-MM-YYYY')} 
                                 placeholder={moment(ReduxSearchData.check_out).format('DD-MM-YYYY')} className='mt-2 w-100 p-1' style={{height:'40px'}}  />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-md-12 mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}>Boards  </h6>
                            {hoteldetail.hotel_boards.map((item, index) => (
                              <input key={index}  className='mt-2 w-100' type='text' value={item.board_name} width='fullWidth' style={{height:'40px'}} />
                            ))}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-12  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}>Segments </h6>
                            {hoteldetail.hotel_segments.map((item, index) => (
                              <input key={index} className='mt-2 w-100' type='text' value={item} width='fullWidth' style={{height:'40px'}} ></input>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6 col-md-12 mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }}>Country</h6>
                            <input type='text' value={hoteldetail.hotel_country} className='mt-2 w-100' width='fullWidth' style={{height:'40px'}}  />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12 col-md-12  mt-1'>
                        <div class='single-tour-feature mb-3'>
                            <h6 style={{ fontSize: '16px' }} >City</h6>
                            <input type='text' value= {hoteldetail.hotel_city} className='mt-2 w-100' width='fullWidth' style={{height:'40px'}}  />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-2'>
                  <h4>Description</h4>
                </div>
                <div className='col-md-9'>
                  <h4>{hoteldetail.hotel_name}</h4>
                  <p className='text-justify'>{hoteldetail.description} </p>
                </div>
              </div>
            </section>
            <section id='section-2' className='mt-2'>
              <div className='row'>
                <div className='col-md-10'>
                  {hoteldetail.rooms_options.map((item, index) => (
                    <div
                      key={index}
                      className='pt-2 pb-2'
                    >
                        <div className='row mt-3'>
                          <div className='col-md-2'>
                            <h5 className='tc fw-bold mb-2'>Room Type</h5>
                          </div>
                          <div className='col-md-6 px-5' style={{ height: '15em', overflow: 'hidden' }}>
                            {item.rooms_images && item.rooms_images.length > 0 ? (
                              <img
                                src={item.rooms_images[0]}
                                alt={item.room_name}
                              />
                            ) : (
                              <img src={noImage} alt='Default Image' />
                            )}
                          </div>
                          <div className='col-md-3'>
                            <h5 className='mt-2'>{item.room_name}</h5>
                            <ul>
                              {item.rooms_facilities &&
                              item.rooms_facilities.length > 0 ? (
                                <RoomFacilities item={item}/>
                              ) : (
                                <li>No facilities available</li>
                              )}
                            </ul>
                            <div className='row p-2'>
                              <div className='d-flex'>
                                {item.cancliation_policy_arr &&
                                item.cancliation_policy_arr.length > 0 ? (
                                  new Date(
                                    item.cancliation_policy_arr[0].from_date
                                  ) > todayDate ? (
                                    <h6> <span style={{ color: 'green' }}>RISK FREE!</span> No cancellation fee before {moment(item.cancliation_policy_arr[0].from_date).format('DD-MM-YYYY')} (property local time)</h6>
                                  ) : (
                                    <h6 style={{ color: 'red' }}>Non Refundable</h6>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row  py-3'>
                          <div className='col-md-2 mb-2'>
                            <h5 className='tc fw-bold'>Room Detail</h5>
                          </div>
                          <div className='col-md-3 px-5 col-sm-4'>
                            <h6 className='tc mb-2'>Meal Type</h6>
                            <p style={{fontSize:'12px'}}>{item.board_id}</p>
                          </div>
                          <div className='col-md-2 col-sm-4'>
                            <h6 className='tc mb-2'>Room Capacity</h6>
                            <div style={{fontSize:'12px'}}>
                              <p> {' '} {item.adults} Adults , {item.childs} children </p>
                              <p> {item.rooms_qty} Rooms</p>
                            </div>
                          </div>
                          <div className='col-md-4 card-price-section item-from col-sm-4' style={{ alignItems: 'center' }}>
                            <div className='price text-center p-card-price'>
                              {showPrice ?(
                              <h6>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {renderPrice(calculateMarkup(item.rooms_total_price))}</h6>
                              ):(
                                <h6>{hoteldetail.hotel_curreny} {calculateMarkup(item.rooms_total_price)}</h6>

                              )}
                              </div>
                            <h6 className='mt-2'>
                              {' '}
                              Price For {daysBetween} Nights
                            </h6>
                            {/* <button className='btn mt-2 btn-primary btn-block select-styling search-btn1'>
                          Book
                        </button> */}
                            <div class='form-group' data-toggle='buttons'>
                              <label
                                onClick={event =>
                                  selectedRoomData(index, event)
                                }
                                class='btn btn-default mt-2 select-room--checkbox primary'
                              >
                                <i class='fa fa-fw'></i>
                                <input
                                  id={index}
                                  autoComplete='off'
                                  className='room-check'
                                  type='checkbox'
                                  checked={selectedRooms.some(
                                    room => room.index === index
                                  )}
                                  onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                />
                                Select Room
                              </label>
                            </div>
                          </div>
                        </div>
                    </div>
                  ))}
                </div>
                <div className='col-md-2'>
                  <div className='theiaStickySidebar'>
                    <div className='book-now button-4 cart-btn'>
                      <button
                        className='btn mt-1 btn-block search-btn1 p-2'
                        onClick={BookRoom}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id='section-3'>
              <div class='row property-highlights mt-3'>
                <div className='col-md-3'>
                  <h4>Property Highlights</h4>
                </div>
                <div class='col-md-8 property-highlights__content'>
                  <div class='row'>
                    {hoteldetail.hotel_facilities.map((item, index) => (
                      <div key={index} class='col-md-3 col-6'>
                        <div class='item'>
                          <i
                            style={{ color: '#d39d00' }}
                            class='awe-icon fa fa-check'
                            aria-hidden='true'
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          <span>{item}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <section id='section-5'>
              <div className='row mt-3'>
                <div className='col-md-3'>
                   <h4 id='section-4'>Near By Places</h4>
                  <h5 className='flight-heading mt-2 w-50 mb-2'>Location</h5>
                </div>
                <div class='col-sm-12 col-md-8'>
                  <div style={{ width: '100%' }}>
                    <iframe
                      width='100%'
                      height='300'
                      frameborder='0'
                      scrolling='no'
                      marginheight='0'
                      marginwidth='0'
                      src={mapUrl}
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
            <section id='section-7'>
              <div className='row mt-5'>
                <div className='col-md-3'>
                  <h4 className='mb-3'>Reviews</h4>
                  <button
                        className='btn btn-primary btn-block select-styling search-btn1 rounded-1 mb-2'
                        style={{ width: 'unset'}}
                      >
                        Write a review
                  </button>
                </div>
                <div className='col-md-8'>
                  <div id='reviews'>
                    <div class='rating-info'>
                      <div class='average-rating-review good'>
                        <span class='count'>7.5</span>
                        <em>Average rating</em>
                        <span>Good</span>
                      </div>
                      <ul class='rating-review'>
                        <li>
                          <em>Facility</em>
                          <span>7.5</span>
                        </li>
                        <li>
                          <em>Human</em>
                          <span>9.0</span>
                        </li>
                        <li>
                          <em>Service</em>
                          <span>9.5</span>
                        </li>
                        <li>
                          <em>Interesting</em>
                          <span>8.7</span>
                        </li>
                      </ul>
                    </div>
                    <div id='comments'>
                      <ol class='commentlist'>
                        <li>
                          <div class='comment-box'>
                            <div class='comment-body'>
                              <p class='meta'>
                                <h6>Nguyen Gallahendahry</h6>
                                <span class='time'>December 10, 2012</span>
                              </p>
                              <div class='description'>
                                <p>
                                  Takes me back to my youth. I love the design of
                                  this soda machine. A bit pricy though..!
                                </p>
                              </div>

                              <div class='rating-info mt-3'>
                                <div class='average-rating-review good'>
                                  <span class='count'>7.5</span>
                                  <em>Average rating</em>
                                  <span>Good</span>
                                </div>
                                <ul class='rating-review'>
                                  <li>
                                    <em>Facility</em>
                                    <span>7.5</span>
                                  </li>
                                  <li>
                                    <em>Human</em>
                                    <span>9.0</span>
                                  </li>
                                  <li>
                                    <em>Service</em>
                                    <span>9.5</span>
                                  </li>
                                  <li>
                                    <em>Interesting</em>
                                    <span>8.7</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class='comment-box'>
                            <div class='avatar'>
                              <img src='images/img/demo-thumb.jpg' alt='' />
                            </div>
                            <div class='comment-body'>
                              <p class='meta'>
                                <h6>James Bond not 007</h6>
                                <span class='time'>December 10, 2012</span>
                              </p>
                              <div class='description'>
                                <p>
                                  Takes me back to my youth. I love the design of
                                  this soda machine. A bit pricy though..!
                                </p>
                              </div>

                              <div class='rating-info mt-3'>
                                <div class='average-rating-review good'>
                                  <span class='count'>7.5</span>
                                  <em>Average rating</em>
                                  <span>Good</span>
                                </div>
                                <ul class='rating-review'>
                                  <li>
                                    <em>Facility</em>
                                    <span>7.5</span>
                                  </li>
                                  <li>
                                    <em>Human</em>
                                    <span>9.0</span>
                                  </li>
                                  <li>
                                    <em>Service</em>
                                    <span>9.5</span>
                                  </li>
                                  <li>
                                    <em>Interesting</em>
                                    <span>8.7</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class='comment-box'>
                            <div class='avatar'>
                              <img src='images/img/demo-thumb.jpg' alt='' />
                            </div>
                            <div class='comment-body'>
                              <p class='meta'>
                                <h6>Bratt not Pitt</h6>
                                <span class='time'>December 10, 2012</span>
                              </p>
                              <div class='description'>
                                <p>
                                  Takes me back to my youth. I love the design of
                                  this soda machine. A bit pricy though..!
                                </p>
                              </div>

                              <div class='rating-info mt-2'>
                                <div class='average-rating-review fine'>
                                  <span class='count'>5.0</span>
                                  <em>Average rating</em>
                                  <span>Fine</span>
                                </div>
                                <ul class='rating-review'>
                                  <li>
                                    <em>Facility</em>
                                    <span>7.5</span>
                                  </li>
                                  <li>
                                    <em>Human</em>
                                    <span>9.0</span>
                                  </li>
                                  <li>
                                    <em>Service</em>
                                    <span>9.5</span>
                                  </li>
                                  <li>
                                    <em>Interesting</em>
                                    <span>8.7</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        </Layout>
      )}
      <div id="mybutton">
      <button  onClick={BookRoom} class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn">Book Now</button>
      </div>
    </>
  )
}

export default HotelDetail;
