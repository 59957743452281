import React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle,
    faStar,
  faGlobe,
  faCalendarCheck,
  faHeart,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import { CustomerDomainName,ApiEndPoint,ActivityToken } from "../GlobalData/GlobalData";

function ActivityCard({newActivityListing}){
  const [isLoading, setIsLoading] = useState(false);
  var Domainpath=CustomerDomainName();
  var navigation=useNavigate();
const showDetail=(id)=>{
  navigation(`/activity_details/${id}`);
};
 
    return (
        <>
        <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
             {newActivityListing.map((item,index)=>(
                <div key={index} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row' style={{backgroundColor:'white'}}>
                      <div className='col-md-3 item-from'>
                          <div className="position-relative">
                            <img
                              className='tour-img'
                              src={Domainpath+'/public/images/activites/'+item.featured_image}
                              alt=''
                            />
                             <div class="wishlist"> <FontAwesomeIcon icon={faHeart} /> </div>
                             <a href="..." onClick={()=>showDetail(item.id)} className='a-card-price'>
                                {item.title}
                              </a>
                          </div>
                      </div>
                      <div className='col-md-6 px-3 py-4'>
                        <p className='card-star'>
                          {item.starts_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.starts_rating )
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                          (Activity Hours : {item.duration})
                        </p>
                        <h5 className='card-title mt-1'>
                          <a
                           onClick={()=>showDetail(item.id)}
                            className='p-card-title' style={{ color: 'green' }}
                          >
                           {item.title}
                          </a>
                        </h5>
                        <h6 className='departure-date mt-1' style={{textAlign:'left', color:'black'}}>
                         {' '}   <FontAwesomeIcon icon={faGlobe} />{' '}  {item.location}
                        </h6>
                          <div class='row mt-1'>
                            <div class='single-feature-titles'>
                              <p style={{fontSize:'13px'}}>{item.activity_content}</p>
                            </div>
                          </div>
                        <div class='item-address mt-1' style={{ color: 'green' }}>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCalendarCheck} />
                          </i>{' '}
                          {item.activity_date}{' '}
                        </div>
                      </div>
                      <div className='col-md-3 text-center card-price-section py-5' style={{borderLeft:'1px solid #dedede'}}>
                        <h6 className='text-center p-view-detail'>Map View</h6>
                        <div className='price text-center p-card-price'>
                           <h6 className="mt-1 mb-1">
                              <super>
                                {item.currency_symbol} {item.sale_price}
                              </super>
                            </h6>
                        </div>
                        <button
                        onClick={()=>showDetail(item.id)}
                          className='btn btn-primary select-styling search-btn1 form-control mb-1'
                        >
                          Book Now
                        </button>

                        <div style={{ fontSize: '11px' }}>
                          <FontAwesomeIcon
                            color='#bd1c1cc9'
                            icon={faInfoCircle}
                          />{' '}
                          Inclusive of VAT and Taxes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
    )
}

export default ActivityCard;